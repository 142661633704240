// import external css
@import "./animate.min.css";
@import "_variables.scss";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

// navigation
.bg-light {
  background-color: #ffffff !important;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);

  .navbar-nav {
    .nav-link {
      position: relative;
      color: $navText !important;

      &::after {
        position: absolute;
        width: 0px;
        height: 2px;
        content: "";
        background-color: #784a9e;
        left: 0px;
        top: 40px;
        transition: all 0.4s ease-in-out;
      }

      &.active,
      &:hover {
        color: $navText !important;

        &::after {
          width: 100%;
        }
      }
    }
  }
}

.dropdown {
  .nav-link {
    text-transform: uppercase;

    &::after {
      display: none;
    }
  }

  &:hover {
    .dropdown-menu {
      display: block;
      margin-top: 0;
    }

    .nav-link {
      font-weight: bold;

      &::after {
        transform: rotate(180deg);
      }
    }
  }
}

.nav-link {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.right_nav_bar {
  position: relative;

  li.nav-item {
    margin-left: 10px;

    &:last-child {
      margin-left: 0px;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        left: 0px;
        width: 1px;
        background: #666666;
        height: 20px;
        top: 10px;
      }
    }
  }
}

// Course Details Page
// Banner
.top_banner_wrapper {
  padding: 0px 0;
  .top_image_header {
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    .course_top_banner_img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      z-index: 9;
    }
    &::before {
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.76) 100%
      );
      background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.76) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.76) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#c2000000', GradientType=0);

      position: absolute;
      content: "";
      top: 0px;
      left: 0px;
      z-index: 99;
    }
  }

  .banner_content_bottom {
    color: #333333;
    padding-top: 30px;
  }

  .banner_content {
    color: $white;

    .title {
      color: $white !important;
      font-size: 60px;
      font-weight: bold;
    }
  }

  .banner_card {
    width: 100%;
    position: relative;
    top: -205px;
    margin-bottom: -160px;
    .card {
      padding: 20px;
      border-radius: 10px;
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    }
  }
}

.btn-primary {
  padding: 6px 15px;
  font-size: 14px;
  text-align: center;
  border-color: #784a9e !important;
  color: $white;
  background: #784a9e;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0px 0px 0 rgb(198 121 227 / 25%);
  transform: translateY(0px);
}

.btn-primary:hover {
  background: #0062cc;
  border-color: #0062cc !important;
  box-shadow: 0 3px 8px 0 rgb(198 121 227 / 25%);
  transform: translateY(-2px);
}

// Loading
#fountainG {
  position: relative;
  width: 240px;
  height: 29px;
  margin: 20px auto;
}
.fountainG {
  position: absolute;
  top: 0;
  background-color: #784a9e;
  width: 29px;
  height: 29px;
  -moz-animation-name: bounce_fountainG;
  -moz-animation-duration: 1.3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-direction: normal;
  -moz-transform: scale(0.3);
  -moz-border-radius: 19px;
  -webkit-animation-name: bounce_fountainG;
  -webkit-animation-duration: 1.3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  -webkit-transform: scale(0.3);
  -webkit-border-radius: 19px;
  -ms-animation-name: bounce_fountainG;
  -ms-animation-duration: 1.3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-direction: normal;
  -ms-transform: scale(0.3);
  -ms-border-radius: 19px;
  -o-animation-name: bounce_fountainG;
  -o-animation-duration: 1.3s;
  -o-animation-iteration-count: infinite;
  -o-animation-direction: normal;
  -o-transform: scale(0.3);
  -o-border-radius: 19px;
  animation-name: bounce_fountainG;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  transform: scale(0.3);
  border-radius: 19px;
}

#fountainG_1 {
  left: 0;
  -moz-animation-delay: 0.52s;
  -webkit-animation-delay: 0.52s;
  -ms-animation-delay: 0.52s;
  -o-animation-delay: 0.52s;
  animation-delay: 0.52s;
}

#fountainG_2 {
  left: 30px;
  -moz-animation-delay: 0.65s;
  -webkit-animation-delay: 0.65s;
  -ms-animation-delay: 0.65s;
  -o-animation-delay: 0.65s;
  animation-delay: 0.65s;
}

#fountainG_3 {
  left: 60px;
  -moz-animation-delay: 0.78s;
  -webkit-animation-delay: 0.78s;
  -ms-animation-delay: 0.78s;
  -o-animation-delay: 0.78s;
  animation-delay: 0.78s;
}

#fountainG_4 {
  left: 90px;
  -moz-animation-delay: 0.91s;
  -webkit-animation-delay: 0.91s;
  -ms-animation-delay: 0.91s;
  -o-animation-delay: 0.91s;
  animation-delay: 0.91s;
}

#fountainG_5 {
  left: 120px;
  -moz-animation-delay: 1.04s;
  -webkit-animation-delay: 1.04s;
  -ms-animation-delay: 1.04s;
  -o-animation-delay: 1.04s;
  animation-delay: 1.04s;
}

#fountainG_6 {
  left: 150px;
  -moz-animation-delay: 1.17s;
  -webkit-animation-delay: 1.17s;
  -ms-animation-delay: 1.17s;
  -o-animation-delay: 1.17s;
  animation-delay: 1.17s;
}

#fountainG_7 {
  left: 180px;
  -moz-animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
  -ms-animation-delay: 1.3s;
  -o-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

#fountainG_8 {
  left: 210px;
  -moz-animation-delay: 1.43s;
  -webkit-animation-delay: 1.43s;
  -ms-animation-delay: 1.43s;
  -o-animation-delay: 1.43s;
  animation-delay: 1.43s;
}

@-moz-keyframes bounce_fountainG {
  0% {
    -moz-transform: scale(1);
    background-color: #784a9e;
  }

  100% {
    -moz-transform: scale(0.3);
    background-color: #ffffff;
  }
}

@-webkit-keyframes bounce_fountainG {
  0% {
    -webkit-transform: scale(1);
    background-color: #784a9e;
  }

  100% {
    -webkit-transform: scale(0.3);
    background-color: #ffffff;
  }
}

@-ms-keyframes bounce_fountainG {
  0% {
    -ms-transform: scale(1);
    background-color: #784a9e;
  }

  100% {
    -ms-transform: scale(0.3);
    background-color: #ffffff;
  }
}

@-o-keyframes bounce_fountainG {
  0% {
    -o-transform: scale(1);
    background-color: #784a9e;
  }

  100% {
    -o-transform: scale(0.3);
    background-color: #ffffff;
  }
}

@keyframes bounce_fountainG {
  0% {
    transform: scale(1);
    background-color: #784a9e;
  }

  100% {
    transform: scale(0.3);
    background-color: #ffffff;
  }
}

//.loading_container {
//  height: calc(100vh - 150px);
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}

.web_logo {
  max-height: 40px;
}

.category_header_wrapper {
  background-size: cover;
  padding: 50px 0;
  background-position: bottom;

  .cat_head_content {
    .title {
      font-weight: bold;
      color: $navText;
      font-size: 40px;
    }

    .description {
      font-size: 16px;
    }
  }

  .cat_head_image {
    img {
      max-width: 100%;
    }
  }
}

.blog_cat_card {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  background: $white;
  transition: all 0.3s ease-in-out;
  transform: scale(1);
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    transform: scale(1.1);
  }
}

.course_cat_card_wrapper {
  background: $white;
  position: relative;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-in-out;
  transform: scale(1);
  cursor: pointer;

  .category_image {
    height: 150px;
    overflow: hidden;

    img {
      width: 100%;
      min-height: 150px;
    }
  }

  .category_content {
    .title {
      font-size: 18px;
      display: block;
      color: $navText;
      font-weight: bold;
      padding: 15px 10px;
      text-align: center;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &:hover {
    transform: scale(1.04);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  }
}

.course_card_wrapper {
  background: $white;
  position: relative;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  overflow: hidden;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.07);
  transition: all 0.6s ease-in-out;
  transform: scale(1);
  margin-bottom: 20px;

  .card_image {
    height: 160px;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      min-height: 130px;
    }
  }

  .card_content {
    padding: 15px 25px;
    min-height: 240px;

    .title {
      font-size: 18px;
      display: block;
      color: $navText;
      font-weight: bold;
      text-align: left;

      &:hover {
        text-decoration: none;
      }
    }

    .duration,
    .batch,
    .description {
      color: $text;
      font-size: 14px;

      strong {
        color: $gray;
      }
    }

    .description {
      * {
        color: $text;
        font-size: 14px;

        strong {
          color: $gray;
        }
      }
    }

    .btn-link-danger {
      color: $red;
      font-size: 14px;
      font-weight: bold;
    }
  }

  &:hover {
    transform: scale(1.04);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  }
}

.courses_wrapper {
  padding-bottom: 50px;
}

.mobile_app_wrapper {
  padding: 40px 0px;

  .mobile_content {
    padding-top: 50px;

    h1 {
      font-weight: bold;
    }
  }
}

.promo_video {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  img {
    width: 100%;
  }
}

.course_features {
  padding: 40px 0px;

  .course_features_wrapper {
    h2 {
      font-weight: bold;
    }

    p {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}

.selection_wrapper {
  padding: 40px 0;
}

.selection_wrapper {
  display: block;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;

  .student_image {
    width: 150px;
    height: 150px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
      min-height: 150px;
    }
  }

  .select_content {
    padding-top: 20px;

    .st_name {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 0px;
    }

    .st_score {
      font-weight: normal;
      font-size: 16px;
    }
  }
}

.text-bold {
  font-weight: bold;
}

.Blog_header_wrapper {
  // height: 400px;
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  margin-bottom: 40px;
}

.blog_categories_wrapper {
  min-height: 300px;
  position: relative;

  .card {
    transition: all 0.4s ease-in-out;
    box-shadow: 0px 0px 15px 5px #efefef;
    -webkit-box-shadow: 0px 0px 15px 5px #efefef;
    -moz-box-shadow: 0px 0px 15px 5px #efefef;
    -ms-box-shadow: 0px 0px 15px 5px #efefef;
    -o-box-shadow: 0px 0px 15px 5px #efefef;
    border-color: #ffffff;
    margin-bottom: 30px;
    top: 0px;

    &:hover {
      text-decoration: none;
      top: -5px;
    }

    .card-body {
      .card-title {
        font-size: 16px;
        line-height: 24px;
        margin: 20px 0 15px;
        padding-bottom: 12px;
        overflow: hidden;
        color: #101010;
        font-weight: 400;
      }

      .desc,
      .desc * {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 25px;
        overflow: hidden;
        color: #696969 !important;
      }

      .card_footer {
        display: flex;
        justify-content: space-between;

        .date {
          font-size: 12px;
          color: #b1b1b1;
          padding-top: 1px;
          display: block;
        }

        .category {
          color: #696969;
          font-size: 14px;
        }
      }
    }
  }
}

.small_blog_wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;

  &:hover {
    text-decoration: none;
  }

  .blog_img {
    width: 100px;
    max-width: 100px;

    img {
      width: 100%;
      border-radius: 2px;
    }
  }

  .blog_body {
    padding-left: 10px;

    .title {
      color: #666666;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 6px;
      line-height: 1;
    }

    .blog_description {
      display: inline;
      color: #999999;
      font-size: 13px;
      font-weight: normal;
      line-height: normal;
    }
  }
}

.slick-next {
  right: 10px !important;
}

.slick-prev {
  left: 10px !important;
}

.banner_wrapper .slick-slide {
  min-height: 400px !important;
}

.slick-prev,
.slick-next {
  z-index: 999;
}

.footer {
  background: #363636;
  margin-top: 20px;
  padding: 40px 0;
  color: #ffffff;

  .about_us {
    .footer-logo {
      max-width: 120px;
      display: block;
    }

    .company_name {
      display: block;
      margin-top: 10px;
    }

    .year {
      font-size: 13px;
      opacity: 0.8;
    }

    .social_media {
      padding: 0px;
      list-style: none;
      display: flex;
      flex-direction: row;

      li {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        background: rgba($color: $white, $alpha: 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        a {
          color: $white;
          opacity: 0.7;
          transition: all 0.4s ease-in-out;
        }

        &:hover {
          a {
            opacity: 1;
          }
        }
      }
    }
  }

  .footer_section {
    .title {
      color: $red;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .footer_list {
      list-style: none;
      padding: 0px;
      margin: 0px;

      li {
        display: block;

        a {
          color: $white;
          font-size: 14px;
          padding: 5px 0;
          letter-spacing: 0.5px;
        }
      }
    }

    .playstore_logo {
      max-width: 150px;
      display: block;
      margin-top: 20px;
    }
  }
}

.testimonial_wrapper {
  padding: 40px 0px;
  background: $white;

  .testimonials_content {
    margin: 0 -10px;
  }
}

.section_heading {
  .title {
    font-size: 35px;
    font-weight: bold;
  }
}

.testimonial_card {
  background: $white;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  padding: 15px;
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.2);
  margin: 10px;

  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;

    .header_img {
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 50%;

      .test_image {
        width: 100%;
        min-height: 70px;
      }
    }

    .test_content {
      padding-left: 15px;

      .name {
        font-size: 18px;
        font-weight: bold;
        padding-top: 15px;
        padding-bottom: 2px;
        margin: 0px;
      }

      .designation {
        font-size: 13px;
        opacity: 0.7;
      }
    }
  }

  .test_container {
    padding-left: 40px;
    position: relative;
    min-height: 100px;
    background-repeat: no-repeat;

    p {
      margin: 0px;
      color: #9e9e9e;
      padding-top: 20px;
    }
  }
}

.why_us_wrapper {
  padding: 40px 0;
  background-color: #f7fafc;
}

.learn_box_wrapper {
  position: relative;
  background: $white;
  overflow: hidden;
  border-radius: 5px;
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
  padding-bottom: 25px;
  width: calc(100% - 60px);

  .lb_image {
    width: 80px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 20px;

    img {
      max-width: 100%;
    }
  }

  .lb_content {
    min-height: 100px;

    .title {
      text-align: center;
      color: #bdbdbd;
      font-size: 20px;
      font-weight: bold;
    }

    .text {
      padding: 0 15px;
      margin: 0px;
      text-align: center;
      color: #666666;
    }
  }
}

.popular_courses_wrapper {
  padding: 40px 0;

  .courses_wrapper_container {
    padding-top: 30px;

    .nav-justified {
      flex-direction: column;
      border-radius: 5px;
      overflow: hidden;
      // border: 1px solid #d3d3d3;
    }

    .nav-pills {
      .nav-item {
        -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.08);
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.08);
        border: 1px solid #eeeeee;
        border-radius: 10px;
        position: relative;
        min-height: 60px;
        overflow: hidden;
        margin: 5px 0px;

        &:last-child {
          border-bottom: 0px;
        }

        .nav-link {
          text-align: left;
          border-radius: 0px;
          color: #515151;
          font-size: 16px;
          padding: 18px 15px;
          position: relative;

          &.active {
            color: $white;
            background: #784a9e;
            background: -moz-linear-gradient(left, #784a9e 0%, #784a9e 100%);
            background: -webkit-linear-gradient(left, #784a9e 0%, #784a9e 100%);
            background: linear-gradient(to right, #784a9e 0%, #784a9e 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#784a9e', endColorstr='#784a9e', GradientType=1);
          }

          .right_icon {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 22px;
          }
        }
      }
    }
  }
}

.courses_list_wrapper {
  padding-left: 15px;
}

.accordion__button {
  position: relative;
}

.accordion__button:before {
  position: absolute;
  right: 10px;
}

.req_callBack {
  padding: 6px 15px;
  cursor: pointer;
  background: $white;
  text-align: center;
  display: inline-block;
  color: #784a9e;
  border: 1px solid #784a9e;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 10px;
  position: relative;
  top: 3px;
  transition: all 0.4s ease-in-out;

  &:hover {
    background: #784a9e;
    color: $white;
  }

  i {
    font-size: 18px;
    position: relative;
    transform: rotate(10deg);
    left: 5px;
    top: 2px;
  }
}

.bell {
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.small_form {
  margin-bottom: 30px;

  label {
    font-size: 15px;
    position: relative;
    top: 5px;
    color: #555555;
  }

  .form-control {
    height: 50px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #000000;
      opacity: 0.2;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #000000;
      opacity: 0.2;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #000000;
      opacity: 0.2;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: #000000;
      opacity: 0.2;
    }

    &:focus {
      border-color: $red;
    }
  }
}

.only_mobile_visible {
  display: none;
}

.btn-large {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-item {
  text-transform: uppercase;
}

.custom_content_container {
  .cc_list {
    padding: 0px;
    list-style: none;
    margin-bottom: 15px;

    li {
      margin-bottom: 10px;
      font-size: 14px;
      color: #666666;

      i {
        width: 30px;
        color: green;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.htel_wrapper {
  padding: 40px 0;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  position: relative;

  .how_to_image {
    max-height: 600px;
    position: absolute;
    right: 300px;
    top: 120px;
    height: 877px;
  }

  .row_wrapper {
    height: 170px;

    .htel_content {
      width: 360px;

      &.right_content {
        text-align: right;
      }

      &.left_content {
        margin-left: auto;
      }

      h3 {
        font-weight: 700;
        font-size: 24px;
        color: #383838;
        letter-spacing: -0.5px;
        line-height: 30px;
      }

      p {
        opacity: 0.5;
        font-size: 14px;
        color: #000;
        line-height: 24px;
        margin-top: 5px;
      }
    }
  }
}

.how-to-btn {
  background: #2271f5;
  padding: 6px 15px;
  margin-right: 15px;
  border: 1px solid #2271f5;
  color: #ffffff;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;

  &:hover {
    color: #ffffff;
    text-decoration: none;
    background: #1962db;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #784a9e;
}

.enroll_blocks_wrapper {
  .enroll_block {
    display: block;
    width: 250px;
    margin: 0 auto;
    margin-bottom: 50px;
    position: relative;
    min-height: 230px;

    &::after {
      position: absolute;
      width: 240px;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      height: 30px;
      bottom: -40px;
      background-image: url("../assets/images/steps/shadow.png");
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.5;
    }

    img {
      width: 100%;
    }

    .enroll_content {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      padding-left: 100px;
      display: flex;
      align-items: center;
      padding-top: 10px;

      p {
        margin: 0px;
        line-height: normal;
        font-weight: bold;
      }
    }
  }
}

.slick-slide img {
  width: 100% !important;
}

.text-normal {
  font-weight: normal !important;
  letter-spacing: 1px;
}

.about_us_wrapper {
  padding-top: 100px;
  padding-bottom: 40px;

  .tab_italic_line {
    font-style: italic;
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    opacity: 0.5;
    margin-top: 20px;
  }

  .about_content {
    padding-top: 20px;
    border-top: 1px solid #eeeeee;

    p {
      font-size: 16px;
      text-align: justify;
      color: #777777;
    }
  }
}

.about_author {
  margin-top: 50px;
  margin-bottom: 30px;

  .author_wrapper {
    position: relative;
    overflow: hidden;
    -webkit-border-top-left-radius: 30px;
    -webkit-border-bottom-right-radius: 30px;
    -moz-border-radius-topleft: 30px;
    -moz-border-radius-bottomright: 30px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);

    img {
      max-width: 100%;
    }
  }

  .author_content {
    .name {
      font-weight: bolder;
    }

    .about_a {
      font-style: italic;
      font-size: 18px;
      color: #777777;
    }
  }

  .contact_info {
    a {
      color: #454545;
      font-size: 18px;
      display: block;

      i {
        font-size: 20px;
        width: 30px;
        color: #784a9e;
      }
    }
  }
}

.cblock_card {
  background: $white;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15);
  padding: 15px 20px;
  height: 180px;
  border: 1px solid #f2f2f2;
  position: relative;

  .title {
    font-size: 18px;
    font-weight: bold;
    color: #515151;
  }

  .description {
    * {
      color: #9e9e9e;
      font-size: 13px;
      line-height: 1.1;
      font-weight: normal;
      display: inline;
    }

    img {
      display: none;
    }
  }

  .cb_link {
    color: #e90029;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
    position: absolute;
    width: 100%;
    right: 26px;
    bottom: 10px;
    transition: all 0.4s ease-in-out;

    &:hover {
      text-decoration: none;
      color: #000000;
    }
  }
}

// folowus_wrapper
.folowus_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px 0;

  .bottom_label {
    margin-top: 10px;
  }

  .follow_circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0px 30px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    transition: all 0.3s ease-in-out;

    &:hover {
      text-decoration: none;
      color: #ffffff;
      transform: scale(1.1);
    }

    &.facebook {
      background: #4867aa;
    }

    &.instagram {
      background: #d6249f;
      background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    }

    &.youtube {
      background: #ff0000;
    }

    &.telegram {
      background: #28a8e9;
    }

    i {
      font-size: 40px;
      color: #ffffff;
    }
  }
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.modal-header {
  // padding-top: 0px;
  position: relative;

  .close_modal {
    position: absolute;
    right: 15px;
    top: 5px;
    cursor: pointer;
  }
}

.is_success_wrapper {
  min-width: 400px;
  text-align: center;

  .isIcons {
    font-size: 80px;
    color: green;
  }

  h2 {
    font-weight: bold;
  }
}
.blog_image {
  img {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .visible_only_desktop {
    display: none;
  }
  .only_mobile_visible {
    display: inline-block;
  }
  .htel_wrapper {
    height: auto;
  }
  .navbar-collapse {
    background: #ffffff;
    position: absolute;
    left: 0px;
    top: 100px;
    width: 100%;
    z-index: 99999;
  }
  .how-to-btn {
    display: inline-block;
    width: 44%;
  }

  .right_nav_bar li.nav-item {
    margin-right: 0px;
  }
  .right_nav_bar {
    padding: 0 20px;
  }
  .navbar-collapse {
    .nav-item.dropdown {
      padding: 0 20px;
    }
  }
  .right_nav_bar li.nav-item {
    margin-left: 0px;
  }
  .req_callBack {
    position: absolute;
    top: 1px;
    right: 10px;
    padding: 8px 15px;
  }
  .right_nav_bar li.nav-item:last-child::after {
    display: none;
  }
  .banner_wrapper .slick-slide {
    height: auto !important;
    min-height: auto !important;
  }
  .courses_list_wrapper {
    padding-left: 0px;
    padding-top: 10px;
  }
  .course_card_wrapper {
    margin: 15px 0;
  }
  .htel_wrapper .how_to_image {
    display: none;
  }
  .htel_wrapper .row_wrapper .htel_content {
    width: 100%;
    text-align: center;
  }
  .htel_wrapper .row_wrapper .htel_content.right_content {
    text-align: center;
  }
  .learn_box_wrapper {
    width: 100%;
  }
  .learn_box_wrapper {
    margin-bottom: 30px;
  }
  .mobile_app_wrapper .mobile_content h1 {
    margin-bottom: 30px;
    text-align: center;
    font-size: 35px;
  }
  .section_heading .title {
    text-align: center;
  }
  .Blog_details_wrapper .blog_details_wrapper .blog_title {
    font-size: 26px;
    line-height: 1.1;
  }
  .promo_video {
    margin-bottom: 25px;
  }
  .course_features_wrapper {
    margin-bottom: 30px;
  }
  .top_banner_wrapper .banner_content .title {
    font-size: 36px;
    text-align: center;
  }
  .top_banner_wrapper .banner_content p {
    text-align: center;
  }
  .slick-slide img {
    width: 100%;
  }
  .category_header_wrapper .cat_head_content .title {
    font-size: 30px;
  }
  .dropdown:hover .dropdown-menu {
    margin-bottom: 15px;
  }
  .footer {
    text-align: center;
  }
  .footer .about_us .social_media {
    justify-content: center;
  }
  .footer .about_us .footer-logo,
  .footer .footer_section .playstore_logo {
    margin: auto;
  }
  .enroll_blocks_wrapper .enroll_block {
    margin-bottom: 100px;
  }
  .is_success_wrapper {
    width: 80%;
  }
  .folowus_wrapper {
    flex-direction: column;
    align-items: center;

    .follow_box {
      margin-bottom: 30px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .visible_only_desktop {
    display: none;
  }
  .only_mobile_visible {
    display: inline-block;
  }
  .htel_wrapper {
    height: auto;
  }
  .navbar-collapse {
    background: #ffffff;
    position: absolute;
    left: 0px;
    top: 100px;
    width: 100%;
    z-index: 99999;
  }
  .how-to-btn {
    display: inline-block;
    width: 45%;
    text-align: center;
  }
  .right_nav_bar li.nav-item:last-child {
    margin-left: 0px;
  }
  .right_nav_bar li.nav-item {
    margin-right: 0px;
  }
  .right_nav_bar {
    padding: 0 20px;
  }
  .navbar-collapse {
    .nav-item.dropdown {
      padding: 0 20px;
    }
  }
  .right_nav_bar li.nav-item {
    margin-left: 0px;
  }
  .req_callBack {
    position: absolute;
    top: 1px;
    right: 10px;
    padding: 8px 15px;
    width: 50%;
  }
  .right_nav_bar li.nav-item:last-child::after {
    display: none;
  }
  .banner_wrapper .slick-slide {
    height: auto !important;
    min-height: auto !important;
  }
  .courses_list_wrapper {
    padding-left: 0px;
    padding-top: 10px;
  }
  .course_card_wrapper {
    margin: 15px 0;
  }
  .htel_wrapper .how_to_image {
    display: none;
  }
  .htel_wrapper .row_wrapper .htel_content {
    width: 100%;
    text-align: center;
  }
  .htel_wrapper .row_wrapper .htel_content.right_content {
    text-align: center;
  }
  .learn_box_wrapper {
    width: 100%;
  }
  .learn_box_wrapper {
    margin-bottom: 30px;
  }
  .mobile_app_wrapper .mobile_content h1 {
    margin-bottom: 30px;
    text-align: center;
    font-size: 35px;
  }
  .section_heading .title {
    text-align: center;
  }
  .Blog_details_wrapper .blog_details_wrapper .blog_title {
    font-size: 26px;
    line-height: 1.1;
  }
  .promo_video {
    margin-bottom: 25px;
  }
  .course_features_wrapper {
    margin-bottom: 30px;
  }
  .top_banner_wrapper .banner_content .title {
    font-size: 36px;
    text-align: center;
  }
  .top_banner_wrapper .banner_content p {
    text-align: center;
  }
  // .Blog_header_wrapper {
  //   height: 150px;
  // }
  .slick-slide img {
    width: 100%;
  }
  .category_header_wrapper .cat_head_content .title {
    font-size: 30px;
  }
  .dropdown:hover .dropdown-menu {
    margin-bottom: 15px;
  }
  .is_success_wrapper {
    width: 80%;
  }
}

@media (min-width: 1281px) and (max-width: 1400px) {
  .htel_wrapper .how_to_image {
    right: 230px;
  }
  .home_slider_wrapper {
    min-height: 350px !important;
    height: auto !important;

    .slider {
      min-height: 350px !important;
      height: auto !important;

      a {
        min-height: 350px !important;
        height: auto !important;
        display: block;

        img {
          min-height: 350px !important;
          height: auto !important;
          display: block;
        }
      }
    }
  }
}

@media (min-width: 1281px) {
  .home_slider_wrapper {
    min-height: 350px !important;
    height: auto !important;

    .slider {
      min-height: 350px !important;
      height: auto !important;

      a {
        min-height: 350px !important;
        height: auto !important;
        display: block;

        img {
          min-height: 350px !important;
          height: auto !important;
          display: block;
        }
      }
    }
  }
  .pl-14 {
    padding-left: 4.4rem;
  }
  .pr-14 {
    padding-right: 4.4rem;
  }
}

.slider_slideBanner {
  a {
    position: relative !important;
    width: 100% !important;
    z-index: 9999 !important;
  }
}

.btn-link {
  color: $red;

  &:hover {
    text-decoration: none;
    color: $red;
  }
}

.home_slider_wrapper .slider img {
  width: 100% !important;
}

.whatsapp_wrapper {
  position: fixed;
  display: inline-block;
  right: 15px;
  bottom: 10px;
  z-index: 999;
  text-align: center;
  &:hover {
    .wp_title {
      text-decoration: none;
    }
    text-decoration: none;
  }
  .whatsapp_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    .wp_title {
      font-size: 14px;
      margin-bottom: 5px;
      display: block;
      color: #333333;
      text-shadow: 1px 1px 0px #ffffff, 1px 1px 0px #ffffff;
      font-weight: bold;
    }
  }
  img {
    max-width: 60px;
  }
}

.mapouter {
  margin-bottom: -20px;
}

.contact_form_wrapper {
  margin-bottom: 30px;

  .form-control {
    min-height: 45px;
    border-radius: 0px;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $red;
    }
  }

  textarea {
    resize: none;
    min-height: 90px !important;
  }
}

.contact_us_wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  .contact_us_inner {
    padding: 1.25rem;
    border: 1px solid #eee;
    border-radius: 0.75rem;
    position: relative;
    -webkit-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.05);
    .left_section {
      background: #784a9e;
      height: 100%;
      padding: 30px 20px;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 80px;
      border-radius: 0.75rem;
      a {
        color: #fff;
        &:hover {
          text-decoration: none;
        }
      }
      .title {
        font-size: 32px;
        font-weight: bold;
      }
      .description {
        opacity: 0.8;
      }
      .all_info {
        margin-top: 2.5rem;
        .ff_row {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .ff_row_icon {
            width: 50px;
            font-size: 25px;
            position: relative;
            top: 2px;
          }
          .content {
            font-weight: normal;
            font-size: 18px;
            a {
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
    }
    .right_section {
      .form-control {
        width: 100%;
        min-height: 55px;
        border: 2px solid transparent;
        transition: all 0.4s ease-in-out;
        padding: 10px 15px;
        border-radius: 10px;
        background-color: #f6f6f6;
        &:focus,
        &:hover {
          outline: none;
          box-shadow: none;
          border-color: #784a9e;
        }
      }
    }
  }
}

.bg_red {
  background: $red;
  margin-bottom: 20px;
}

label.error {
  color: $red;
  margin: 0px;
  font-weight: normal;
  font-size: 12px;
}

.contact_success_wrapper {
  min-height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 100px;
    display: block;
    margin: 40px auto 0;
  }

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;

    &.circle {
      -webkit-animation: dash 0.9s ease-in-out;
      animation: dash 0.9s ease-in-out;
    }

    &.line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
      animation: dash 0.9s 0.35s ease-in-out forwards;
    }

    &.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
      animation: dash-check 0.9s 0.35s ease-in-out forwards;
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
}

.contact_info_wrapper {
  color: $white;
  padding-top: 20px;
}

.contact_info_list {
  margin: 0px;
  padding-left: 0px;
  list-style: none;

  li {
    display: flex;
    padding-top: 10px;

    i {
      width: 30px;
      position: relative;
      top: 4px;
    }

    a {
      color: $white;
    }
  }
}

.feed_back_wrapper {
  position: fixed;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-wrap: nowrap;
  z-index: 999;

  .feedBack_handle {
    background: $red;
    color: #ffffff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
    margin-right: -1px;
    z-index: 999;
  }

  .feedback_body {
    width: 0px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    transition: all 0.4s ease-in-out;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    overflow: hidden;
    background: $white;

    &.width0 {
      width: 250px;
    }

    * {
      transition: all 0.1s ease-in-out;
    }

    .title {
      position: absolute;
      width: 100%;
      left: 0px;
      text-align: center;
      top: 0px;
      padding: 5px;
      font-size: 16px;
      background: $red;
      color: $white;
    }

    .feedBack_icon {
      margin: 0px 5px;
      font-size: 55px;
      color: #cccccc;
      cursor: pointer;

      &:hover {
        color: $red;
      }
    }
  }
}

.slick-slide {
  * {
    &:focus {
      outline: none !important;
    }
  }
}

.category_name {
  position: absolute;
  left: 0px;
  bottom: -10px;
  background: rgba(213, 45, 69, 0.8);
  color: $white;
  padding: 0 10px;
  text-transform: uppercase;
}

// for navbar custom info banner
.custom_info_navbar {
  display: block;
  color: $white;
  padding: 10px 0;
  position: relative;
  color: $white;
  background: #784a9e;
  background: -moz-linear-gradient(left, #784a9e 0%, #784a9e 100%);
  background: -webkit-linear-gradient(left, #784a9e 0%, #784a9e 100%);
  background: linear-gradient(to right, #784a9e 0%, #784a9e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#784a9e', endColorstr='#784a9e', GradientType=1);

  &:hover {
    text-decoration: none;
    color: $white;
  }

  p {
    font-weight: bold;

    span.active {
      background: $white;
      color: $red;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 10px;
      font-size: 14px;
    }
  }
}

.hide_banner {
  position: absolute;
  right: 20px;
  top: 22px;
  cursor: pointer;
  z-index: 999;

  i {
    font-size: 24px;
    color: $white;
  }
}

// Optin form
.optinForm_overlay {
  * {
    transition: all 0.4s ease-in-out;
  }

  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-in-out;

  .optin_form_wrapper {
    width: 500px;
    position: relative;
    transition: all 0.4s ease-in-out;

    .closeButton {
      position: absolute;
      right: -15px;
      top: -15px;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      background: $red;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      z-index: 9;
    }

    .optin_form_body {
      background: $white;
      min-height: 100px;
      border-radius: 10px;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}

@media (max-width: 767px) {
  .custom_info_navbar {
    padding-right: 10px;

    p {
      font-size: 12px;

      span.active {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 12px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 3px;
      }
    }
  }
  .hide_banner {
    right: 5px;
    top: 0;
  }
  .top_banner_wrapper {
    &::before {
      display: none;
    }

    background-image: none !important;
    height: auto;

    .course_top_banner_img {
      position: relative;
    }

    .banner_content {
      padding-top: 20px;

      .title {
        color: #ffffff !important;
        font-size: 24px;
        text-align: left;
      }

      p {
        color: #666666;
      }
    }
  }
  .section_heading .title {
    font-size: 24px;
  }
  .optin_form_wrapper {
    width: 90% !important;
  }
}

@media (min-width: 1280px) {
  .top_banner_wrapper {
    overflow: hidden;

    .course_top_banner_img {
      height: auto;
      min-height: 500px;
    }
  }
}

.old_price {
  font-size: 18px;
  text-decoration: line-through;
  color: #999;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .optinForm_overlay {
    display: none !important;
  }
}

// for new design front
.login-container {
  padding-top: 40px;
  padding-bottom: 20px;
}

.login_wrapper {
  padding: 25px 20px;
  overflow: hidden;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}

.login-title {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 0px;
}

.text-mute {
  color: #999999;
  font-size: 13px;
  letter-spacing: 0.3px;
  margin-bottom: 5px;
}

.login_header {
  padding-bottom: 5px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 15px;
}

.isError {
  color: red;
}

.login_label {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: -5px;
}

.btn-google {
  background: #5086ec;
  color: #ffffff !important;
  font-size: 16px !important;
  padding-bottom: 10px;
  padding-top: 10px;
  border: 1px solid #5086ec;
  transition: all 0.4s ease-in-out;
  &:hover {
    color: #5086ec !important;
    background: #ffffff !important;
  }
}

.icon_label {
  margin-right: 5px;
}

.kep-login-facebook {
  font-size: 13px !important;
  padding: 10px !important;
  font-weight: normal !important;
  width: 100% !important;
  border-radius: 5px !important;
  text-transform: capitalize !important;
}

.dfc {
  display: flex;
  align-items: center;
  position: relative;
}

.avatar_header {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #784a9e;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  span {
    color: #ffffff;
    font-size: 23px;
  }
}

.user_name {
  text-transform: capitalize;
}

.frontSlider_img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.userPage_wrapper {
  padding: 20px 0px;
}

// user panel sidebar
.sidebar_wrapper {
  background: #ffffff;
  height: 100%;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;

  .user_pic_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user_content {
    padding-top: 20px;

    .user_name {
      text-align: center;
    }

    .user_email {
      font-size: 13px;
      color: #cccccc;
      text-align: center;
    }

    .profile_links {
      margin-left: -10px;
      margin-right: -10px;

      .navlink {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 10px 15px;
        text-decoration: none;
        background: #f9f9f9;
        margin-bottom: -2px;
        border-top: 2px solid #f2f2f2;
        border-bottom: 2px solid #f2f2f2;
        color: #666666;
        font-size: 14px;

        i {
          width: 25px;
        }

        &:hover {
          color: #ffffff;
        }

        &.active {
          background: #784a9e;
          color: #ffffff;
        }

        &.denger-text {
          color: #784a9e;

          &:hover {
            color: #784a9e;
          }
        }
      }
    }
  }
}

/* Sweep To Right */
.hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #784a9e;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-sweep-to-right:hover,
.hvr-sweep-to-right:focus,
.hvr-sweep-to-right:active {
  color: white;
}

.hvr-sweep-to-right:hover:before,
.hvr-sweep-to-right:focus:before,
.hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.edit_profile_icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  color: #784a9e;

  &:hover {
    color: #784a9e;
    text-decoration: none;
  }
}

.user_wrapper_container {
  .card {
    background: #ffffff;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border-color: #ffffff !important;
  }

  .card-header {
    background: #ffffff;
  }

  .header-title {
    margin: 0px;
    font-size: 16px;
    position: relative;
    top: 8px;
  }
}

.userPage_wrapper {
  .img_wrapper {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 5px solid #eeeeee;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      min-height: 200px;
      border-radius: 50%;
    }

    .change_pic_icon {
      position: absolute;
      right: -10px;
      bottom: 20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #dddddd;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.9);
      color: #784a9e;
      z-index: 9;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);

      .fileUploader {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 9999;
      }
    }
  }

  .form_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 15px;
    background: #f9f9f9;
    position: relative;
    border-radius: 3px;
    margin-bottom: -5px;

    .form_label {
      width: 100px;
      margin: 0px;
      color: #666666;
    }

    .form_value {
      margin: 0px;
    }
  }
}

.header-title-card {
  margin-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.ReactModal__Overlay {
  z-index: 999999;

  .ReactModal__Content {
    width: 500px;
    height: 500px;
  }
}

.loading_wrapper {
  text-align: center;

  .img-fluid {
    margin-bottom: 20px;
  }
}

.price-cut {
  color: #cccccc;
  font-weight: normal;
  text-decoration: line-through;
  font-size: 18px;
  display: block;
}

.course_content_title {
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
}

.important_info {
  display: flex;
  padding-top: 16px;
  position: relative;
  top: 20px;

  span {
    font-size: 16px;
    display: inline-block;
    font-weight: bold;
    margin-right: 10px;
    color: #784a9e;
  }
}

.item_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  &.completed {
    background: rgba(0, 200, 83, 0.1);
  }

  .row_title {
    margin: 0px;
    padding: 0px;
    position: relative;
    top: 0px;
    margin-left: 20px;
    color: #999999;
  }

  .modal-header {
    padding-top: 15px;

    .modal-title {
      font-size: 1rem;
    }
  }
}

.accordion__button {
  padding: 10px 15px !important;
  font-weight: bold;
}

.accordion__button:before {
  top: 15px;
}

//*{
//  &:focus, &:active, &:hover{
//    outline: none;
//    box-shadow: none;
//  }
//}
.terms_page_header {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #eeeeee;

  .title {
    font-weight: bold;
    color: #303030;
    font-size: 28px;
    margin: 0px;
  }

  p {
    margin: 0px;
    font-size: 13px;
    margin-top: 5px;
    color: #999999;
    font-style: italic;
  }
}

// for loaders
@keyframes ldio-hpfsdhpdhyw {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ldio-hpfsdhpdhyw div {
  left: 93px;
  top: 53px;
  position: absolute;
  animation: ldio-hpfsdhpdhyw linear 1s infinite;
  background: #8e62b2;
  width: 14px;
  height: 14px;
  border-radius: 7px / 7px;
  transform-origin: 7px 47px;
}

.ldio-hpfsdhpdhyw div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9s;
  background: #8e62b2;
}

.ldio-hpfsdhpdhyw div:nth-child(2) {
  transform: rotate(36deg);
  animation-delay: -0.8s;
  background: #8e62b2;
}

.ldio-hpfsdhpdhyw div:nth-child(3) {
  transform: rotate(72deg);
  animation-delay: -0.7s;
  background: #8e62b2;
}

.ldio-hpfsdhpdhyw div:nth-child(4) {
  transform: rotate(108deg);
  animation-delay: -0.6s;
  background: #8e62b2;
}

.ldio-hpfsdhpdhyw div:nth-child(5) {
  transform: rotate(144deg);
  animation-delay: -0.5s;
  background: #8e62b2;
}

.ldio-hpfsdhpdhyw div:nth-child(6) {
  transform: rotate(180deg);
  animation-delay: -0.4s;
  background: #8e62b2;
}

.ldio-hpfsdhpdhyw div:nth-child(7) {
  transform: rotate(216deg);
  animation-delay: -0.3s;
  background: #8e62b2;
}

.ldio-hpfsdhpdhyw div:nth-child(8) {
  transform: rotate(252deg);
  animation-delay: -0.2s;
  background: #8e62b2;
}

.ldio-hpfsdhpdhyw div:nth-child(9) {
  transform: rotate(288deg);
  animation-delay: -0.1s;
  background: #8e62b2;
}

.ldio-hpfsdhpdhyw div:nth-child(10) {
  transform: rotate(324deg);
  animation-delay: 0s;
  background: #8e62b2;
}

.loadingio-spinner-spinner-bql8n33rdvh {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}

.ldio-hpfsdhpdhyw {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ldio-hpfsdhpdhyw div {
  box-sizing: content-box;
}

.main_loader_container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  top: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.8);
  flex-direction: column;

  .loading_title {
    font-size: 20px;
    font-weight: bold;
    position: relative;
    top: -20px;
  }

  .loader_logo {
    width: 40px;
  }
}

.blog_list_header_wrapper {
  padding: 50px 0;
  background: #784a9e;
  color: #ffffff;
  background: -moz-linear-gradient(left, #784a9e 0%, #784a9e 100%);
  background: -webkit-linear-gradient(left, #784a9e 0%, #784a9e 100%);
  background: linear-gradient(to right, #784a9e 0%, #784a9e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#784a9e', endColorstr='#784a9e', GradientType=1);

  .bl_header_title {
    text-align: center;
    font-weight: 600;
    color: #ffffff;
  }

  .bl_header_subTitle {
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    font-size: 16px;
  }

  .bl_header_row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.blogList_container {
  padding: 20px 0;

  .blog_slider_wrapper {
    position: relative;
    -webkit-box-shadow: 0px 16px 32px 0px rgba(23, 43, 77, 0.08);
    -moz-box-shadow: 0px 16px 32px 0px rgba(23, 43, 77, 0.08);
    box-shadow: 0px 16px 32px 0px rgba(23, 43, 77, 0.08);
    border-radius: 5px;
    .owl-nav {
      position: absolute;
      width: 100%;
      top: calc(50% - 108px);
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      z-index: 9;

      .owl-prev {
        padding: 30px 10px;
        background: #ffffff;
        border: 0;
        box-shadow: 1px 8px 16px 0 rgba(0, 0, 0, 0.04);
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .owl-next {
        padding: 30px 10px;
        background: #ffffff;
        border: 0;
        -webkit-border-top-left-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }
}

.blog_card_wrapper {
  .blog_card_image {
    height: 300px;
    overflow: hidden;

    img {
      min-height: 300px;
      width: 100%;
    }
  }

  .blog_card_content {
    padding: 10px 15px;

    .blog_category_label {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .blog_category_chip {
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 700;
        padding: 4px 8px 3px 8px;
        color: rgba(120, 74, 158, 0.7);
        background-color: #f6ecec;
        margin-right: 10px;
        line-height: 1;
        letter-spacing: 1px;
      }

      .blog_publish_date {
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 700;
        padding: 4px 0px 3px 0px;
        color: rgba(120, 74, 158, 0.7);
        line-height: 1;
        letter-spacing: 1px;
      }
    }

    .blog_name {
      height: 80px;
      .blog_card_title {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.6;
        color: #1e1e1e;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .author_name {
      font-size: 0.7rem;
    }
  }
}

.popular_card_wrapper {
  position: relative;
  top: 0px;
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
  padding: 15px;
  border: 1px solid #e3e5f0;
  transition: all 0.4s ease-in-out;

  &:hover {
    top: -5px;
    border-color: rgba(120, 74, 158, 0.7);
    -webkit-box-shadow: 0px 16px 32px 0px rgba(23, 43, 77, 0.08);
    -moz-box-shadow: 0px 16px 32px 0px rgba(23, 43, 77, 0.08);
    box-shadow: 0px 16px 32px 0px rgba(23, 43, 77, 0.08);
  }

  .blog_category_label {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .blog_category_chip {
      text-transform: uppercase;
      font-size: 0.7rem;
      font-weight: 700;
      padding: 4px 8px 3px 8px;
      color: rgba(120, 74, 158, 0.7);
      background-color: #f6ecec;
      margin-right: 10px;
      line-height: 1;
      letter-spacing: 1px;
    }

    .blog_publish_date {
      text-transform: uppercase;
      font-size: 0.7rem;
      font-weight: 700;
      padding: 4px 0px 3px 0px;
      color: rgba(120, 74, 158, 0.7);
      line-height: 1;
      letter-spacing: 1px;
    }
  }

  .blog_name {
    .blog_card_title {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.2;
      color: #1e1e1e;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .author_name {
    font-size: 0.7rem;
  }
}

.blogs_all_wrapper {
  padding-top: 50px;

  .blogs_header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    background: #f9f9f9;
    margin-bottom: 20px;
  }
}

.small_blog_card_wrapper {
  position: relative;
  top: 0px;
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #e3e5f0;
  transition: all 0.4s ease-in-out;

  &:hover {
    top: -5px;
    border-color: rgba(120, 74, 158, 0.7);
    -webkit-box-shadow: 0px 16px 32px 0px rgba(23, 43, 77, 0.08);
    -moz-box-shadow: 0px 16px 32px 0px rgba(23, 43, 77, 0.08);
    box-shadow: 0px 16px 32px 0px rgba(23, 43, 77, 0.08);
  }

  .blog_card_image {
    height: 180px;
    overflow: hidden;

    img {
      min-height: 180px;
      width: 100%;
    }
  }

  .blog_card_content {
    padding: 10px 15px;

    .blog_category_label {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .blog_category_chip {
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 700;
        padding: 4px 8px 3px 8px;
        color: rgba(120, 74, 158, 0.7);
        background-color: #f6ecec;
        margin-right: 10px;
        line-height: 1;
        letter-spacing: 1px;
      }

      .blog_publish_date {
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 700;
        padding: 4px 0px 3px 0px;
        color: rgba(120, 74, 158, 0.7);
        line-height: 1;
        letter-spacing: 1px;
      }
    }

    .blog_name {
      .blog_card_title {
        font-size: 0.9rem;
        font-weight: 500;
        line-height: 1;
        color: #1e1e1e;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .author_name {
      font-size: 0.7rem;
    }
  }
}

.blogs_pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;

    li {
      margin: 0 10px;

      &.active {
        a {
          background: #784a9e;
          color: #ffffff;
        }
      }

      a {
        display: block;
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000;
        border-radius: 3px;
        font-weight: 700;
        -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }
      }
    }
  }
}

.breadcrumb_wrapper {
  padding: 10px 0px;

  span {
    padding: 4px 8px;
    line-height: 1;
    background-color: #eceff6;
    padding-right: 15px;

    a {
      font-size: 0.875rem;
      font-weight: 500;
      color: #6b7c93;
      position: relative;
      margin-right: 5px;
      text-decoration: underline;
    }
  }
}

#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  top: 0;
  left: 0;
}

#progressBar {
  background: linear-gradient(#784a9e, #784a9e);
  transform-origin: top left;
  transform: scale(0, 0);
  opacity: 0;
}

#progressBarContainer,
#progressBar {
  height: 5px;
}

.related_blogs {
  padding: 20px 0px;

  .related_header {
    margin-bottom: 30px;

    .related_header_title {
      text-align: center;
    }
  }
}

.related_blogs_body {
  padding-top: 20px;

  .owl-nav {
    position: absolute;
    width: 100%;
    top: calc(50% - 108px);
    display: flex;
    justify-content: space-between;
    z-index: 0;

    .owl-prev {
      padding: 30px 10px;
      background: #ffffff;
      border: 0;
      box-shadow: 1px 8px 16px 0 rgba(0, 0, 0, 0.04);
      -webkit-border-top-right-radius: 5px;
      -webkit-border-bottom-right-radius: 5px;
      -moz-border-radius-topright: 5px;
      -moz-border-radius-bottomright: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .owl-next {
      padding: 30px 10px;
      background: #ffffff;
      border: 0;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-bottomleft: 5px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .owl-dots {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .owl-dot {
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin-right: 12px;
      background-color: #f6ecec;
      border: 0;
    }
  }
}

.owl-stage {
  .small_blog_card_wrapper {
    &:hover {
      top: 0px;
      box-shadow: none;
    }
  }
}

// custom select options
.custom-select-container {
  display: inline-block;
  min-width: 250px;
  text-align: center;
  position: relative;

  .select-options {
    position: absolute;
    width: 100%;
    z-index: 999;
    margin: 0px;
    max-height: 300px;
    overflow-y: auto;
    background: #ffffff;
    padding: 5px 10px;

    li {
      list-style-type: none;
      padding: 6px 20px;
      background: transparent;
      border-bottom: 2px solid #eeeeee;
      cursor: pointer;
      text-transform: capitalize;

      &:hover {
        background-color: #32a6a6;
        color: #ffffff;
      }
    }
  }

  .selected-text {
    background-color: transparent;
    padding: 6px 20px;
    border-bottom: 2px solid #784a9e;
    margin-left: 20px;
    color: #784a9e;
    padding-right: 35px;
    cursor: pointer;
    text-transform: capitalize;
  }

  .selected-text::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 16px;
    border: 7px solid transparent;
    border-color: #784a9e transparent transparent transparent;
  }

  .selected-text.active::after {
    top: 8px;
    border-color: transparent transparent #fff transparent;
  }
}

.main_container {
  header {
    border-bottom: 1px solid #eeeeee;
    padding: 15px 0;

    .navbar_right_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .cart_wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 15px;

        .cart_icon_wrapper {
          position: relative;
          margin-right: 5px;
          cursor: pointer;

          .budge {
            position: absolute;
            top: 50%;
            font-size: 9px;
            right: 50%;
            color: #784a9e;
            transform: translate(45%, -32%);
            font-weight: bold;
          }
        }
      }

      .user_icon_wrapper {
        display: flex;
        align-items: center;

        a {
          color: #212529;
          position: relative;
          top: 1px;
          margin-left: 5px;
        }
      }

      .nav_icon {
        font-size: 40px;
        color: #000000;
      }
    }
  }
}

.main_header_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .header_logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    .browse_courses_wrap {
      display: flex;
      align-items: center;
      position: relative;
      top: 4px;
      margin-left: 15px;
      padding: 20px 0px;
      .browse_course_label {
        font-size: 13px;
        font-weight: bold;
        padding-left: 10px;
        position: relative;
        top: 1px;
        cursor: pointer;
      }
      .browse_mega_menu_wrapper {
        background: #ffffff;
        padding: 15px;
        position: absolute;
        width: 600px;
        border-radius: 5px;
        top: 50px;
        z-index: 99;
        transition: all 0.4s ease-in-out;
        display: none;
        min-height: 300px;
        max-height: 500px;
        overflow-y: auto;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 40px;
          width: 0;
          height: 0;
          border: 12px solid transparent;
          border-bottom-color: #ffffff;
          border-top: 0;
          margin-left: -12px;
          margin-top: -12px;
        }
        .mega_menu_inner {
          position: relative;
          height: 100%;
          width: 100%;
          .mm_row {
            .mm_row_item {
              width: 200px;
              // position: relative;
              padding: 10px 15px;
              margin-bottom: 10px;
              -webkit-box-shadow: 0px 0px 7px 0px rgba(255, 25, 54, 0.15);
              -moz-box-shadow: 0px 0px 7px 0px rgba(255, 25, 54, 0.15);
              box-shadow: 0px 0px 7px 0px rgba(255, 25, 54, 0.15);
              border-radius: 5px;
              cursor: pointer;
              transition: all 0.4s ease-in-out;
              font-size: 14px;
              font-weight: bold;
              border: 2px solid rgba(255, 25, 54, 0.4);

              .mm_row_content {
                position: absolute;
                left: 190px;
                top: 0px;
                display: none;
                opacity: 0;
                transition: all 0.4s ease-in-out;
                height: 100%;
                padding-left: 20px;
                width: calc(100% - 190px);
                .mm_row_content_inner {
                  width: 100%;
                  height: 100%;
                  .mm_row_content_inner_item {
                    padding: 10px;
                    -webkit-box-shadow: 0px 0px 7px 0px rgba(255, 25, 54, 0.15);
                    -moz-box-shadow: 0px 0px 7px 0px rgba(255, 25, 54, 0.15);
                    box-shadow: 0px 0px 7px 0px rgba(255, 25, 54, 0.15);
                    border-radius: 5px;
                    display: block;
                    cursor: pointer;
                    transition: all 0.4s ease-in-out;
                    margin-bottom: 15px;
                    .mm_row_img {
                      width: 70px;
                      margin-right: 10px;
                    }
                    .item_title {
                      font-size: 13px;
                      font-weight: bold;
                      color: #444444;
                    }
                    &:hover,
                    &:active,
                    &:focus {
                      text-decoration: none;
                      outline: none;
                    }
                  }
                }
              }
              &:hover {
                background-color: #e11936;
                color: #ffffff;
                .mm_row_content {
                  display: block;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      &:hover {
        .browse_mega_menu_wrapper {
          display: block;
        }
      }
    }
  }
}

.right_nav_bar li.nav-item {
  &:first-child {
    margin-left: 0px !important;
  }
}

.cart_container {
  background-color: rgba(64, 63, 63, 0.8);
  position: fixed;
  width: 0;
  height: 100%;
  right: 0;
  top: 0px;
  z-index: 999;
  overflow: hidden;
  transition: all 0.4s ease-in-out;

  &.open {
    width: 100%;
  }

  .cart_container_inner {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }

  .drawer {
    position: absolute;
    width: 0px;
    right: 0px;
    height: 100%;
    background: #ffffff;
    z-index: 999;
    transition: all 0.4s ease-in-out;
    transition-delay: 0.3s;

    &.open {
      width: 400px;
    }

    .header {
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);

      .label {
        color: #784a9e;
        font-size: 14px;
        position: relative;
        top: -2px;
        margin-left: 5px;
      }
    }
    .cart-body {
      padding: 15px;
      height: calc(100% - 40px);
      padding-bottom: 50px;
      position: relative;
      .cart_item {
        border-bottom: 1px solid #eeeeee;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        .cart_image {
          width: 80px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          overflow: hidden;
          border: 1px solid #eeeeee;
          padding: 5px;
          img {
            width: 100%;
          }
        }
        .cart-item-content {
          padding-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          position: relative;
          padding-right: 15px;
          width: 100%;
          .title {
            color: #555555;
            font-size: 14px;
            transition: all 0.4s ease-in-out;
            margin-bottom: 3px;
            &:hover {
              text-decoration: none;
              color: #333333;
            }
          }
          .cart_item_price {
            * {
              margin: 0px;
            }
            .price {
              display: block;
              font-size: 13px;
              font-weight: bold;
            }
            .coupon_wrapper {
              font-size: 12px;
              position: relative;
              background: #f6ecec;
              padding: 3px 10px;
              border-radius: 5px;
              .coupon {
                font-weight: bold;
                color: #784a9e;
              }
            }
          }
          .remove_item {
            position: absolute;
            top: 0;
            right: 10px;
          }
        }
      }
      .cart_footer {
        position: absolute;
        width: 100%;
        left: 0px;
        bottom: 0px;
      }
    }
  }
}
.cart-body-inner {
  height: 100%;
  overflow-y: auto;
}
.cart_summary {
  margin-top: 20px;
  .cart_summary_title {
    font-size: 1.25rem;
    line-height: 1.2em;
    font-weight: 700;
    border-bottom: 1px solid rgb(234, 234, 234);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .cart_details_row {
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    &:not(:last-child) {
      border-bottom: 1px solid rgb(248, 248, 248);
    }
    .item-label {
      color: rgb(92, 92, 92);
    }
    .item-value {
      font-size: 1.1rem;
      font-weight: 700;
    }
  }
}

.add-to-cart-btn-wrapper {
  margin-top: 10px;

  .btn-outline {
    border: 2px solid #784a9e;
    margin-bottom: 10px;

    &:hover {
      background: #784a9e;
      background: linear-gradient(to right, #784a9e 0%, #784a9e 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#784a9e', endColorstr='#784a9e', GradientType=1);
      color: #ffffff;
    }
  }
}

.course_valid_date {
  text-align: center;

  span {
    font-size: 13px;
  }
}

.course_divider {
  border-top: 1px solid #784a9e;
  margin-top: 15px;
  margin-left: -20px;
  margin-right: -20px;
}

.apply_coupon_wrapper {
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 5px;

  .apply_coupon_label {
    color: #784a9e;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
  }

  .coupon_code_wrapper {
    .apply_coupon_form {
      display: flex;
      align-items: center;

      .coupon_input {
        width: 100%;
        height: 34px;
        -webkit-border-top-left-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border: 1px solid #dddddd;
        padding: 5px 15px;
        font-size: 13px;
        transition: all 0.4s ease-in-out;

        &:hover {
          border-color: #784a9e;
        }

        &:focus {
          box-shadow: none;
          border-color: #784a9e;
          outline: none;
        }
      }

      .apply_coupon_btn {
        padding: 3px 10px;
        font-size: 14px;
        text-align: center;
        border-color: #784a9e !important;
        border-width: 1px;
        color: $white;
        height: 34px;
        background: #784a9e;
        background: -moz-linear-gradient(left, #784a9e 0%, #784a9e 100%);
        background: -webkit-linear-gradient(left, #784a9e 0%, #784a9e 100%);
        background: linear-gradient(to right, #784a9e 0%, #784a9e 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#784a9e', endColorstr='#784a9e', GradientType=1);
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .isError {
      font-size: 12px;
    }
  }
}

.icon-spin {
  -webkit-animation: icon-spin 1s infinite linear;
  animation: icon-spin 1s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.applied_coupon_text {
  text-align: center;
  font-size: 13px;
  color: #784a9e;
  font-weight: bold;
  margin-top: 15px;
}
.home_page_wrapper {
  * {
    &:hover,
    &:focus {
      outline: none !important;
    }
  }
}

// Video page css
.video_page_container {
  .video_page_header {
    background: #363636;
    padding: 15px 15px;
    position: relative;
    .title {
      font-size: 18px;
      color: #ffffff;
      margin: 0px;
    }
    .courseContentToggle {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      z-index: 99;
      i {
        color: #ffffff;
        font-size: 20px;
      }
    }
    .home_icon {
      color: #ffffff;
    }
  }
  .video_container {
    display: flex;
    // for video container body part
    .video_container_body {
      width: 100%;
    }
    // for sidebar
    .sidebar_component_wrapper {
      width: 400px;
      height: 100%;

      border-left: 1px solid #eeeeee;
      .sidebar_header {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        border-bottom: 1px solid #eeeeee;
        .title {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
        }
      }
      .sidebar_body {
        min-height: 400px;
        max-height: 500px;
        overflow-y: auto;
        .sidebar_section {
          background: #f9f9f9;
          border-bottom: 1px solid #dddddd;

          .sidebar_section_header {
            padding: 10px;
            border: none;
            width: 100%;
            text-align: left;
            .title {
              font-size: 14px;
              font-weight: bold;
              margin: 0px;
            }
          }
          .sidebar_section_body {
            padding: 0px;
            .item_row {
              position: relative;
              padding: 10px;
              border-bottom: 1px solid #eeeeee;
              cursor: pointer;
              justify-content: flex-start;
              &.active {
                background: #007bff;
                color: #ffffff !important;
                i {
                  color: #ffffff !important;
                }
              }
              &:last-child {
                border-bottom: none;
              }
              .item_name {
                position: relative;
                font-size: 14px;
                top: -2px;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
    .video_tabs_wrapper {
      padding: 10px;
      .nav-tabs {
        .nav-item {
          color: #666666;
          margin: 0px;
          position: relative;
          &.active {
            border-color: transparent;
            &::after {
              content: "";
              width: 100%;
              height: 2px;
              background: #333333;
              position: absolute;
              left: 0;
              bottom: -1px;
            }
          }
        }
      }
      .details_content {
        padding: 15px;
      }
    }
  }
}
.course_revires_container {
  padding: 15px 50px;
  .course_revires_header {
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title {
      font-size: 16px;
      font-weight: bold;
      margin: 0px;
    }
  }
}
// for course Reviews
.review_card_wrapper {
  padding: 15px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  .reviews_body {
    padding: 0px 10px;
  }
  .reiview_user {
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #ffffff;
    }
    .title {
      font-weight: bold;
      display: block;
      margin-bottom: 5px;
      display: flex;
      .rats {
        position: relative;
        top: -2px;
        margin-left: 10px;
      }
    }
    .review_date {
      font-size: 13px;
      color: #444444;
      font-style: italic;
    }
  }
}
// over all reviews
.overall-ratings {
  display: flex;
  .summary {
    padding: 5px 15px;
    h1 {
      color: #ffc107;
    }
    span {
      font-size: 13px;
      display: block;
      text-align: center;
    }
  }
  .overall_details {
    padding-left: 15px;
    width: 100%;
    .overall_progress_row {
      display: flex;
      font-size: 11px;
      .progress {
        width: 100%;
        position: relative;
        margin-left: 5px;
        top: 2px;
        height: 0.5rem;
      }
    }
  }
}

// topic_card_wrapper
.topic_card_wrapper {
  padding: 15px 10px;
  border: 1px solid #eeeeee;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 4px;
  .topic_header {
    display: flex;
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #ffffff;
    }
    .inner {
      padding-left: 10px;

      .name {
        font-weight: bold;
        display: block;
        margin-bottom: 5px;
        display: flex;
      }
      .text-mute {
        position: relative;
        top: -7px;
        display: block;
        margin-bottom: 0px;
        font-size: 12px;
      }
    }
  }
  .comment_description {
    border-top: 1px solid #eeeeee;
    font-size: 14px;
    padding-top: 15px;
    .subject {
      font-weight: bold;
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
    .comment-attechement {
      padding-top: 10px;
      img {
        width: auto;
        max-width: 100%;
      }
    }
  }
  .topic_footer {
    border-top: 1px solid #eeeeee;
    padding-top: 10px;
    margin-top: 15px;
  }
}
.discussion_list_header {
  margin-bottom: 10px;
  .header-title {
    font-size: 16px;
    margin: 0px;
    font-weight: bold;
    position: relative;
    top: 10px;
  }
}
.topic_form_wrapper {
  padding: 15px;
  border-radius: 5px;
  background-color: #f7f7f7;
  margin-bottom: 15px;
  .topic_form_footer {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #dddddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .upload_topic_image {
      padding: 6px 15px;
      border: 1px solid #784a9e;
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      transition: all 0.4s ease-in-out;
      cursor: pointer;
      input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: 9;
        opacity: 0;
        cursor: pointer;
      }
      span {
        font-size: 14px;
        color: #784a9e;
      }
      &:hover {
        background-color: #784a9e;
        span {
          color: #ffffff;
        }
      }
    }
  }
}
.selected_attechement_wrapper {
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
  color: #666666;
  font-size: 13px;
  .close_button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #784a9e;
    color: #ffffff;
    cursor: pointer;
  }
}

.comments_wrapper {
  padding-left: 15px;
}
// for live classes tab
.live_classess_wrapper {
  .live_class_header {
    .title {
      margin: 0px;
      font-weight: bold;
      font-size: 16px;
    }
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
  }
  .live_class_row {
    border: 1px solid #eeeeee;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    .live_class_icon {
      padding: 20px 30px;
      background: #f7f7f7;
      position: relative;
      i {
        color: #666666;
      }
    }
    .live_class_body {
      padding: 5px 15px;
      width: 100%;
      .live_class_title {
        color: #666666;
        font-weight: bold;
        display: block;
        &:hover {
          text-decoration: none;
        }
      }
      .dates {
        position: relative;
        .date {
          font-size: 11px;
          color: #999999;
        }
        .date-sap {
          font-weight: bold;
          padding-left: 10px;
          padding-right: 10px;
          position: relative;
          top: 2px;
        }
      }
    }
  }
}

@-webkit-keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-o-keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.live_class_dot {
  -webkit-animation: blink 2s infinite;
  -moz-animation: blink 2s infinite;
  -o-animation: blink 2s infinite;
  animation: blink 2s infinite;
  background: red;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 50%;
}

// Thank You page
.thank_you_page_wrapper {
  padding: 20px 0;
  .check_sign {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 150px;
    }
  }
  .page-title {
    text-align: center;
    font-size: 18px;
  }
}
.empty_cart_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .cart_empty_img {
    max-width: 200px;
  }
  .cart_title {
    font-size: 16px;
    text-align: center;
    color: #999999;
  }
}
.dashboard_wrapp {
  position: relative;
}
.mobile_verification_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 999;
  .mobile_verification_body {
    position: relative;
    height: 100%;
    padding-top: 100px;
  }
}
.mobile_number_wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  .editButton {
    position: absolute;
    right: 15px;
    top: 60px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
  }
  input {
    height: 50px;
    border: 1px solid #dddddd !important;
    text-align: center;
    font-size: 22px;
    letter-spacing: 1.5px;
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: #784a9e !important;
    }
  }
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .mobile_verification_wrapper {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.3);
  }
}
.otp_wrapper {
  input[type="text"] {
    width: 100% !important;
    height: 45px;
    margin: 0 10px;
    border: 1px solid #dddddd !important;
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: #784a9e !important;
    }
  }
}

// quiz_list_wrapper
.quiz_list_wrapper {
  padding: 30px 0px;
  .quiz_card_container {
    padding: 15px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    background: #ffffff;
    border-radius: 5px;
    margin-bottom: 20px;
    .quiz_header {
      position: relative;
      min-height: 80px;
      overflow: hidden;
      .quiz_card_title {
        font-weight: 600;
        font-size: 16px;
        color: #525354;
      }
    }
    .quiz_hindi_title {
      font-size: 12px;
      padding: 4px 10px;
      background: #f1f1f1;
      border-radius: 3px;
    }
    .small_text {
      font-size: 12px;
    }
    .price_container {
      // border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      padding: 2px 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .card_footer {
      padding-top: 10px;
    }
  }
}
// questio view page
.quiz_view_wrapper {
  position: relative;
  margin: 0px;
  padding: 0px;
  background: #f6f8f9;
  margin-bottom: -20px;
  .quiz_list_header {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    .que_name {
      font-size: 16px;
      margin: 0px;
      font-weight: bold;
    }
    .time_counter {
      background: #f1f1f1;
      padding: 4px 10px;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .language_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      .language_icon {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        font-size: 24px;
      }
    }
  }
  .quiz_list_body {
    position: relative;
    padding: 20px 0;
  }
  .quiz_footer {
    background: #ffffff;
    padding: 20px 0;
  }
}

// ques_slide_wrapper
.ques_slide_wrapper {
  padding: 20px;
  .card {
    border: none;
    min-height: 200px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    .card_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      padding-bottom: 0px !important;
      position: relative;
      .card_title {
        font-size: 14px;
        font-weight: bold;
        opacity: 0.7;
      }
      .icon {
        cursor: pointer;
        padding: 0px 10px;
        position: relative;
        top: -3px;
      }
      .options_wrapper {
        width: 250px;
        position: absolute;
        z-index: 99;
        top: 40px;
        right: 0px;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
        .inner {
          width: 100%;
          position: relative;
          border-radius: 5px;
          background: #ffffff;
          padding: 10px;
          ul {
            margin: 0px;
            padding: 0px;
            list-style: none;
            li {
              font-size: 14px;
              display: block;
              margin: 5px 0px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .card-body {
      position: relative;
      .question_text {
        font-size: 14px;
        font-weight: bold;
        position: relative;
        top: -10px;
      }
      .quiz_options_wrapper {
        position: relative;
        .option_row {
          margin-bottom: 10px;
          border: 1px solid #e0e5e8;
          padding: 20px 15px;
          border-radius: 5px;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          transition: all 0.4s ease-in-out;
          position: relative;
          overflow: hidden;
          &.act {
            .count {
              position: relative;
              span {
                position: relative;
                background: #12ed36;
                overflow: hidden;
                &:before {
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  content: "✓";
                  color: #ffffff;
                  background: #12ed36;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
          .ans_label {
            position: absolute;
            right: -2px;
            bottom: -2px;
            padding: 3px 10px;
            color: #ffffff;
            font-size: 11px;
            &.currect_ans,
            &.user_currect_ans {
              background-color: #12ed36;
              opacity: 0;
            }
            &.user_ans {
              opacity: 0;
            }
          }
          &.ownRightAns {
            border-color: #e11936 !important;
            background: rgba(215, 25, 54, 0.1);
            .user_ans {
              opacity: 1;
              background-color: #e11936;
            }
            &:hover {
              border-color: #e11936 !important;
              background: rgba(215, 25, 54, 0.1) !important;
            }
          }
          &.right_option {
            border-color: #12ed36 !important;
            background: rgba(18, 237, 54, 0.1);
            .currect_ans {
              opacity: 1;
            }
            &.ownRightAns {
              .user_currect_ans {
                border-color: #12ed36 !important;
                opacity: 1;
              }
            }
            &:hover {
              border-color: #12ed36 !important;
              background: rgba(18, 237, 54, 0.1) !important;
            }
          }

          .count {
            height: 100%;
            padding-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              border-radius: 50%;
              background: #718188;
            }
          }
          .qt {
            margin: 0px;
            font-size: 14px;
            font-weight: bold;
            opacity: 0.8;
          }
          &:hover,
          &:focus {
            border-color: #78d7ff;
            background: rgba(120, 215, 255, 0.2);
          }
        }
      }
    }
  }
}
.quiz-report-wrapper {
  padding-top: 30px;
  .quiz_big_title {
    text-align: center;
    font-weight: bold;
    color: #444444;
  }
  .quiz-title {
    font-size: 20px;
    font-weight: bold;
    color: #444444;
    margin-bottom: 10px;
  }
  .quiz-report-states {
    background: #f7f7f7;
    padding: 20px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    .result-card {
      background: #ffffff;
      padding: 15px;
      border-radius: 3px;
      transition: all 0.4s ease-in-out;
      position: relative;
      top: 0px;
      height: 170px;
      -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15);
      &:hover {
        top: -5px;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      }
      &.bg-green {
        background: linear-gradient(45deg, #2ed8b6, #59e0c5);
      }
      &.bg-blue {
        background: linear-gradient(45deg, #4099ff, #73b4ff);
      }
      &.bg-yellow {
        background: linear-gradient(45deg, #ffb64d, #ffcb80);
      }
      .inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.flex-column {
          flex-direction: column;
          align-items: flex-start;
        }
        .content {
          width: 100%;
          .content_title {
            font-weight: bold;
            color: #ffffff;
          }
          .greetText {
            font-size: 12px;
            color: #ffffff;
            margin: 0px;
            position: relative;
            top: 10px;
            span {
              text-transform: capitalize;
            }
          }
        }
        .content-stats-row {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 15px;
          width: 50%;
          .row_icon {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            // background: #ffffff;
            // margin-right: 10px;
            color: #e11936;
            font-size: 16px;
          }
          .row-content {
            padding: 5px;
            .row-content-title {
              color: #ffffff;
              font-size: 12px;
              margin: 0px;
              opacity: 0.8;
            }
            .row-content-result {
              color: #ffffff;
              font-size: 15px;
              font-weight: bold;
              margin: 0px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1281px) {
  .container-fluid-custom {
    width: calc(100% - 80px);
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .container {
    max-width: 80rem !important;
  }
  .container-blog-details {
    max-width: 64rem !important;
    margin: 0 auto;
  }
}

.header_search_wrapper {
  margin-left: 25px;
  width: 100%;
  position: relative;
  .search-inner {
    position: relative;
    min-width: 550px;
    .form-control {
      font-size: 14px;
    }
    .search-icon-wrapper {
      position: absolute;
      right: 10px;
      font-size: 18px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
//search-results
.search-results {
  max-height: 300px;
  overflow-y: auto;
  background: #ffffff;
  position: absolute;
  width: 100%;
  top: 50px;
  z-index: 999;
  .search_result_row {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .search_row_image {
      width: 80px;
      img {
        width: 100%;
      }
    }
    .search_row_content {
      padding-left: 10px;
      .content_title {
        font-weight: bold;
        font-size: 14px;
        color: #444444;
        margin: 0px;
      }
    }
  }
}
// home_blog_wrapper
.home_blog_wrapper {
  padding: 40px 0;
  background: #f5f9fb;
  .hb_header {
    .section_title {
      margin: 0px;
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
}
.blog_name {
  height: 50px;
  overflow: hidden;
}
.hb_footer {
  margin-top: 10px;
}

// gradients
.gr_pink {
  background-image: radial-gradient(circle at 98% 100%, #ff83de, #ea56c3 85%);
}
.gr_blue {
  background-image: radial-gradient(circle at 83% 82%, #74bbff, #1f8ffb 100%);
}
.gr_dark_green {
  background-image: radial-gradient(circle at 100% 95%, #50c5d9, #42a1b1 88%);
}
.gr_purple {
  background-image: radial-gradient(circle at 96% 76%, #9949ff, #7733ce 85%);
}
.gr_orange {
  background: linear-gradient(259.08deg, #f7ac3d 0.91%, #e07900 85.13%);
}
//home_stats_wrapper
.home_stats_wrapper {
  padding: 30px 0;
  background: #f5f9fb;
  .stats_body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    margin: 0 auto;
    .small_container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
    }
    .tweet_title {
      width: 250px;
      font-size: 28px;
      font-weight: 800;
      color: #fff;
      margin-left: -50px;
    }
    .title_new {
      width: 206px;
      height: 18px;
      opacity: 0.5;
      font-size: 14px;
      font-weight: 800;
      margin-left: -95px;
      color: #000;
    }
    .trophy_img {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: 27px;
      margin-right: 30px;
    }
  }
  .stats_body > div:first-of-type {
    grid-column: 1/3;
  }
  .tweetContainer {
    border: 1px solid #dedede;
    background: #fff;
    width: 448px;
    border-radius: 8px;
    padding: 20px;
    .small_title {
      font-size: 20px;
      line-height: 1.6;
      color: #26252f;
    }
    .tweet_footer {
      margin-top: 20px;
      display: flex;
      align-items: center;
      .t_profile {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .t_content {
        padding-left: 10px;
        .t_user_title {
          font-weight: bold;
          font-size: 15px;
          color: #555555;
          margin: 0px;
        }
        .t_user_college {
          opacity: 0.7;
          font-size: 12px;
          color: #272727;
          margin-top: 2px;
          display: block;
        }
      }
    }
  }
}

.tweet_pink {
  border: 1px solid #dedede;
  border-radius: 8px;
  .tweet_wrapper {
    margin-left: 30px;
    margin-top: 17px;
    position: relative;
    .tweet_pink_title {
      width: 118px;
      height: 53px;
      font-size: 34px;
      font-weight: 800;
      letter-spacing: -2px;
      color: #fff;
    }
    .tweet_pink_desc {
      width: 205px;
      height: 22px;
      font-size: 20px;
      font-weight: 800;
      color: #fff;
    }
    .tweet_desc_pink {
      width: 135px;
      height: 36px;
      opacity: 0.5;
      font-size: 14px;
      font-weight: 800;
      color: #000;
      margin-top: 64px;
    }
    .trophy_img_pink {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 20px;
    }
  }
}
.stats_body > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.tweet_ed {
  border: 1px solid #dedede;
  height: 140px;
  position: relative;
  border-radius: 8px;
}
.tweet_start {
  border-radius: 8px;
  border: 1px solid #dedede;
  height: 140px;
  position: relative;
}
.tweet_bestEd {
  border-radius: 8px;
  border: 1px solid #dedede;
  height: 140px;
  position: relative;
}
.tweet_title_desc {
  width: 130px;
  height: 18px;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  margin-left: -170px;
}
.tweet_box_nx {
  border: 1px solid #dedede;
  height: 210px;
  position: relative;
  border-radius: 8px;
  background: #fff;
  margin-top: -70px;
  align-items: flex-start !important;
  padding: 15px;
  .small_title {
    font-size: 20px;
    line-height: 1.6;
    color: #26252f;
  }
  .tweet_footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .t_profile {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .t_content {
      padding-left: 10px;
      .t_user_title {
        font-weight: bold;
        font-size: 15px;
        color: #555555;
        margin: 0px;
      }
      .t_user_college {
        opacity: 0.7;
        font-size: 12px;
        color: #272727;
        margin-top: 2px;
        display: block;
      }
    }
  }
}
.home_video_container {
  padding: 30px 0;
  .youtube_video_container {
    position: relative;
    overflow: hidden;
    width: 100%;
    .player-wrapper {
      position: relative;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    }
    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.home_testimonials {
  .marquee {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
}

// ht_card
.ht_card {
  position: relative;
  background: #ffffff;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 5px;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  margin: 20px 10px;
  .ht_card_image {
    width: 60px;
    height: 60px;
    min-width: 60px;
    margin-top: 10px;
    img {
      width: 100%;
    }
  }
  .ht_card_body {
    padding-left: 10px;
    width: 100%;
    min-width: 320px;
    .ht_card_title {
      font-size: 14px;
      color: #444444;
      .ht_card_institute {
        font-weight: bold;
        color: #333333;
      }
    }
    .ht_card_description {
      font-size: 14px;
      opacity: 0.7;
    }
  }
}
// solution-card
.solution-card {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  .solution-card-header {
    background: #e11936;
    padding: 25px 10px;
    .solution-user-name {
      color: #ffffff;
      font-weight: bold;
      text-align: center;
      font-size: 18px;
      margin: 0px;
      span {
        text-transform: capitalize;
      }
    }
    .solution-tag-line {
      color: #ffffff;
      text-align: center;
      margin: 0px;
      font-size: 12px;
      color: #ffffff;
      opacity: 0.8;
    }
  }
  .solution-card-body {
    padding: 25px 15px;
    .solution-card-inner {
      position: relative;
      display: flex;
      flex-direction: row;
      .solution-stats {
        width: 50%;
        border-right: 1px solid #eeeeee;
        padding-right: 25px;
        padding-left: 10px;
        min-height: 100px;
        .solution-stats-rank {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
          .solution-stats-rank-title {
            text-align: center;
            color: #000000;
            opacity: 0.3;
            margin: 0px;
            font-weight: bold;
          }
        }
        .solution-stats-inner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .solution-stats-column {
            width: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 5px 10px;
            &.active {
              border-right: 1px solid #eeeeee;
              border-left: 1px solid #eeeeee;
            }
            .title {
              text-transform: uppercase;
              font-size: 16px;
              color: #555555;
              margin: 0px;
              margin-bottom: 5px;
            }
            .ans {
              font-weight: bold;
              color: #000000;
            }
          }
        }
      }
      .solution-answers {
        width: 50%;
        padding-left: 15px;
        .solution-answers-title {
          text-transform: uppercase;
          font-size: 16px;
          color: #555555;
          margin: 20px 0;
          text-align: center;
        }
        .solution-answers-inner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 20px;
          .circle {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            &.currect {
              span {
                color: #ffffff;
                background: #3fad19;
              }
            }
            &.incurrect {
              span {
                color: #ffffff;
                background: #e11936;
              }
            }
            &.skipped {
              span {
                color: #ffffff;
                background: #b8b3b4;
              }
            }
            span {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

.solution-card-body-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .solution-answers {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 20%;
    .circle {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      &.currect {
        span {
          color: #ffffff;
          background: #3fad19;
        }
      }
      &.incurrect {
        span {
          color: #ffffff;
          background: #e11936;
        }
      }
      &.skipped {
        span {
          color: #ffffff;
          background: #b8b3b4;
        }
      }
      span {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .solution-content {
    width: 80%;
  }
}
.__react_component_tooltip {
  text-transform: capitalize;
}
.solution_wraap {
  .quiz-report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .language_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      .language_icon {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        font-size: 24px;
      }
    }
  }
  .slider_wrapper {
    .ques_slide_wrapper {
      position: relative;
      .card {
        .card {
          box-shadow: none !important;
        }
      }
    }
  }
}
.solution_wraap {
  .slider_wrapper {
    position: relative;
  }
  .carousel_nav_wrapper {
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
    transform: translateY(-50%);
    z-index: 99;
    .handle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e11936;
      color: #ffffff;
      transition: all 0.4s ease-in-out;
      position: absolute;
      transform: scale(1);
      cursor: pointer;
      &.prev {
        left: 15px;
      }
      &.next {
        right: 15px;
      }
      &:hover,
      &:focus {
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        transform: scale(1.1);
      }
    }
  }
}
// mocks_page_wrapper
.mocks_page_wrapper {
  position: relative;
  padding-bottom: 80px;
  header,
  .quiz_list_header {
    background: #383838 !important;
    .page_header_column {
      display: flex;
      align-items: center;
      .company_logo {
        padding-right: 15px;
        margin-right: 15px;
        border-right: 1px solid #595959;
      }
      .que_name {
        display: flex;
        flex-direction: column;
        .que_category {
          color: #ffffff;
          font-size: 12px;
          font-weight: normal;
        }
        .que_name_text {
          font-weight: normal;
          color: #ffffff;
        }
      }
    }
    .language_wrapper {
      .language_icon {
        color: #ffffff;
      }
    }
    .time_counter {
      background: transparent;
      color: #ffffff;
      .time_image {
        width: 25px;
        margin-right: 10px;
        img {
          width: 100%;
        }
      }
    }
  }
  .quiz_list_body {
    height: calc(100vh - 170px);
  }
}
// mock_view_footer
.mock_view_footer {
  position: fixed;
  width: 100%;
  left: 0px;
  bottom: 0px;
  z-index: 99;
  padding: 20px 0px;
  background: #ffffff;
  -webkit-box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.05);
  .mock_view_footer_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .mock_footer_column {
      .btn-outline {
        font-size: 13px;
        border: 1px solid #d9d9d9;
        padding: 6px 18px;
        transition: all 0.4s ease-in-out;
        border-radius: 6px;
        color: #383838 !important;
        &:hover,
        &:focus {
          background: #383838 !important;
          color: #ffffff !important;
        }
      }
      .btn-filled {
        font-size: 13px;
        border: 1px solid #d9d9d9;
        padding: 6px 18px;
        transition: all 0.4s ease-in-out;
        border-radius: 6px;
        background: #383838 !important;
        color: #ffffff !important;
        // &:hover,
        // &:focus {
        //   background: #ffffff !important;
        //   color: #383838 !important;
        // }
      }
    }
  }
}

// mock_ques_wrapper
.mock_ques_wrapper {
  .card {
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    * {
      &:focus {
        outline: none;
      }
    }
    .mock_ques_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 10px;
      border-bottom: 1px solid #eeeeee;
      .mock_part {
        display: flex;
        align-items: center;
        .circle-btn {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #eef0f8;
          border: none;
        }
      }
    }
    .ques_slide_wrapper {
      .card {
        box-shadow: none;
      }
    }
  }
  .mock_right_column_wrapper {
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 20px 15px;
    margin: -20px 0px;
    position: relative;
    border-radius: 0px;
    height: calc(100vh - 150px);
    .ans_colors {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid #eeeeee;
      .chip {
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;
        border-radius: 30px;
        background: #e2ecf8;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #414850;
        font-size: 11px;
        font-weight: normal;
        padding-right: 15px;
        .chip_circle {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          color: #ffffff;
          margin-right: 10px;
          &.bg_gray {
            background: #808080;
          }
          &.bg_success {
            background: #46a86d;
          }
          &.bg_danger {
            background: #e01a33;
          }
          &.bg_marked {
            background: #6a65b7;
          }
        }
        .title {
          white-space: nowrap;
        }
      }
    }
    .answers_wrapper {
      position: relative;
      .title {
        text-align: center;
        font-weight: bold;
        color: #414850;
      }
      .answers_wrapper_inner {
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        align-items: center;
        .mock_ans_chip {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          color: #ffffff;
          margin: 10px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          .review_label {
            width: 100%;
            height: 100%;
            background: #6a65b7;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 0px;
            top: 0px;
            opacity: 0;
          }
          &.bg_gray {
            background: #808080;
          }
          &.bg_success {
            background: #46a86d;
          }
          &.bg_danger {
            background: #e01a33;
          }
          &.bg_marked {
            background: #6a65b7;
            &.review {
              .review_label {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
.mock_wues_title {
  font-weight: bold;
  margin-left: 10px;
}
.mock_title_main {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.right_user_column {
  align-items: center;
  .user_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      min-height: 60px;
    }
  }
  .user_name {
    font-size: 18px;
    font-weight: bold;
  }
}
// logos_wrapper home page
.logos_wrapper {
  .logos-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px -50px;
    .logo_item {
      width: 120px;
      margin: 15px 25px;
      img {
        width: 100%;
      }
    }
  }
}
// announcement_card_wrapper
.announcement_card_wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
  .announcement_card_image {
    height: 150px;
    position: relative;
    overflow: hidden;
    img {
      min-height: 130px;
      width: 100%;
    }
    .gr_label {
      background: #ff416c; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to right,
        #ff4b2b,
        #ff416c
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to right,
        #ff4b2b,
        #ff416c
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      position: absolute;
      z-index: 9;
      bottom: 0px;
      left: 0px;
      padding: 5px 10px;
      width: 100%;
      color: #ffffff;
      font-size: 12px;
    }
  }
  .announcement_card_content {
    padding: 10px 12px;
    .announcement_card_title {
      font-weight: bold;
      font-size: 14px;
      margin: 0px;
    }
    .content {
      line-height: normal;
      font-size: 12px;
      margin-top: 5px;
    }
  }
}

// stats_wrapper
.stats_wrapper {
  background: #26252f;
  padding-top: 20px;
  margin-bottom: -20px;
  .top_section {
    padding-bottom: 50px;
  }
  .big_title {
    color: #ffffff;
    font-weight: bold;
    font-size: 60px;
    color: #fff;
    letter-spacing: -1px;
    line-height: 70px;
  }
  .stats_row {
    position: relative;
    margin-bottom: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .stats_icon {
      width: 80px;
      margin-right: 15px;
      img {
        width: 100%;
      }
    }
    .stats_content {
      .title {
        font-weight: 800;
        font-size: 48px;
        color: #fff;
        letter-spacing: -1.75px;
        margin: 0px;
      }
      .sub_title {
        font-weight: 500;
        font-size: 18px;
        color: #9898a3;
        letter-spacing: 0;
        line-height: 32px;
        position: relative;
        top: -10px;
      }
    }
  }
  .stats_footer {
    background: #2e2d3a;
    padding-top: 150px;
    position: relative;
    margin-top: 50px;
    .container {
      position: relative;
    }
    .features {
      position: absolute;
      top: -230px;
      left: 0px;
      width: 100%;
      .featureItem {
        height: 160px;
        padding: 16px 0 16px 32px;
        background-image: linear-gradient(177deg, #3d3b4c, #26252f);
        -webkit-box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        .value {
          margin-bottom: 16px;
        }
        .classesGradient {
          background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#ff9e9b),
            to(#ef7b79)
          );
          background-image: linear-gradient(180deg, #ff9e9b, #ef7b79);
        }
        .boardsGradient {
          background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#ffe78d),
            to(#fec77c)
          );
          background-image: linear-gradient(180deg, #ffe78d, #fec77c);
        }
        .examsGradient {
          background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#bef2c8),
            to(#89e1b4)
          );
          background-image: linear-gradient(180deg, #bef2c8, #89e1b4);
        }

        .quantity {
          font-weight: 800;
          font-size: 40px;
          color: #fff;
          letter-spacing: -3px;
        }
        .unit {
          font-weight: 800;
          font-size: 32px;
          margin-left: 14px;
          letter-spacing: 0;
        }
      }
    }
  }
}
// video_controler
.main_video_player_container {
  position: relative;
  .to_p {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: rbba(0, 0, 0, 0);
  }
  .users_overlay {
    position: absolute;
    z-index: 100;
    opacity: 0.6;
    transition: all 0.4s ease-in-out;
    span {
      color: #000000;
      display: block;
      font-size: 15px;
      line-height: 1;
      transition: all 0.4s ease-in-out;
    }
  }
  .video_player_screen {
    display: none;
  }
}
.video_player_screen {
  height: 100%;
  min-height: 70vh;
}
.video_player_screen > div {
  width: 100% !important;
  height: 100% !important;
  min-height: 70vh;
  iframe {
    width: 100% !important;
    height: 100% !important;
    min-height: 70vh;
  }
}
.main_video_player_container > .to_p + .video_player_screen {
  display: block;
}
.video_controler_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .center_controls {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    margin-top: -50px;
    .play_button {
      color: #ffffff;
      cursor: pointer;
      opacity: 0.7;
      transform: scale(1);
      transition: all 0.4s ease-in-out;
      background: rgba(0, 0, 0, 0.3);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      &:hover {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }
  .bottom_controls {
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
    padding: 30px;
    display: flex;
    flex-direction: column;
    .extra_controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #ffffff;
      .play_button_sm {
        cursor: pointer;
        &.mr {
          margin-right: 40px;
        }
        &.ml {
          margin-left: 40px;
        }
        &.videoTime {
          font-size: 13px;
        }
        &.backword_icon {
          img {
            transition: all 0.2s ease-in-out;
            transform: rotate(0deg);
          }
          &:active {
            img {
              transform: rotate(-45deg);
            }
          }
        }
        &.forward_icon {
          img {
            transition: all 0.2s ease-in-out;
            transform: rotate(0deg);
          }
          &:active {
            img {
              transform: rotate(45deg);
            }
          }
        }
        &.fullScreenIcon {
          img {
            transition: all 0.2s ease-in-out;
            transform: scale(1);
          }
          &:hover,
          &:active {
            img {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}
.rangeslider,
.rangeslider .rangeslider__fill {
  box-shadow: none !important;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #784a9e !important;
}
.rangeslider .rangeslider__handle {
  background: #ffffff !important;
  box-shadow: none !important;
  border-color: transparent !important;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 15px !important;
  height: 15px !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
  box-shadow: none !important;
  background-color: #ffffff !important;
  width: 13px !important;
  height: 13px !important;
  top: 5px !important;
  left: 5px !important;
  display: none !important;
}
.rangeslider-horizontal {
  height: 4px !important;
}

.volume_slider_wrapper {
  position: relative;
  width: 0px;
  overflow: hidden;

  transition: all 0.4s ease-in-out;
  .rangeslider-horizontal {
    height: 5px !important;
  }
  .rangeslider-horizontal .rangeslider__handle {
    width: 15px !important;
    height: 15px !important;
  }
  .rangeslider-horizontal .rangeslider__handle:after {
    box-shadow: none !important;
    background-color: #ffffff !important;
    width: 9px !important;
    height: 9px !important;
    top: 2px !important;
    left: 2px !important;
  }
}
.video_playback_rate {
  margin-left: 20px;
  position: relative;
  .budge {
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 2px 5px;
    background: #784a9e;
    color: #ffffff;
    border-radius: 50%;
    font-size: 9px;
  }
  .speed_list {
    position: absolute;
    width: 100px;
    background: #000000;
    padding: 10px 3px;
    top: -100px;
    right: 0px;
    border-radius: 5px;
    .speed_list_item {
      display: block;
      background: transparent;
      transition: all 0.4s ease-in-out;
      font-size: 12px;
      padding: 2px 10px;
      cursor: pointer;
      &:hover,
      &.active {
        background: #ffffff;
        color: #000000;
      }
    }
  }
}
.volume_container {
  display: flex;
  align-items: center;
  &:hover {
    .volume_slider_wrapper {
      width: 100px;
      padding-left: 10px;
    }
  }
}
.right_controls {
  display: flex;
  align-items: center;
}
// .main_video_player_container {
//   .center_controls,
//   .bottom_controls {
//     opacity: 0;
//     transition: all 0.4 ease-in-out;
//     * {
//       transition: all 0.4 ease-in-out;
//     }
//   }
//   &.visible {
//     .center_controls,
//     .bottom_controls {
//       opacity: 1;
//       transition-delay: 0.5s;
//     }
//   }
// }

.home_Toppers_section {
  padding: 30px 0;
}

.topper_card_wrapper {
  background: #ffffff;
  border-radius: 15px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #f7f7f7;
  .topper_card_images {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 15px;
    img {
      width: 100%;
      min-height: 120px;
    }
  }
  .topper_card_content {
    .name {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: #515151;
      margin-bottom: 7px;
    }
    .institute {
      font-family: "Roboto";
      font-weight: 400;
      text-align: center;
      font-size: 12px;
      color: #515151;
    }
    .percentage {
      color: #3ea819;
      font-family: "Roboto";
      font-weight: bold;
      text-align: center;
      font-size: 22px;
      margin-bottom: 0px;
    }
  }
}
// all_toppers_wrapper
.all_toppers_wrapper {
  padding-top: 30px;
  .filters {
    margin-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
  }
}
.noScroll {
  overflow: hidden !important;
}

// cart page
.cart_page_wrapper {
  padding-top: 40px;
  padding-bottom: 30px;
  min-height: 400px;
  .card {
    border-color: #eeeeee;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    .card-header {
      background: #ffffff;
      border-color: #eeeeee;
      .card-title {
        font-size: 18px;
        font-weight: 700;
      }
    }
    .cart_delete_btn {
      background: $red;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 13px;
      cursor: pointer;
    }
  }
}
.product_item_wrapper {
  padding: 10px 0;
  display: flex;
  justify-content: flex-start;
  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
  }
  .product_item_image {
    width: 120px;
    min-width: 120px;
    img {
      width: 100%;
    }
  }
  .product_item_content {
    padding-left: 15px;
    width: 100%;
    .title {
      font-size: 20px;
    }
    .item_row {
      padding: 0px;
      margin: 4px 0px;
      color: #666666;
      .category_title {
        font-size: 14px;
      }
    }
  }
}

.tb_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #eeeeee;
  .price {
    font-weight: bold;
  }
}

// bundle_card_wrapper
.bundle_card_wrapper {
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  .bundle_image {
    height: 220px;
    overflow: hidden;
    position: relative;
    img {
      min-height: 220px;
      width: 100%;
    }
  }
  .content {
    padding: 10px;
    .bundle_title {
      color: #050505;
      display: block;
      &:hover {
        text-decoration: none;
      }
    }
    .bundle_content {
      color: #666666;
      .bundle_item {
        font-size: 13px;
        text-transform: capitalize;
      }
    }
  }
}
.progress_course {
  background: #f1f1f1;
  border-radius: 5px;
  overflow: hidden;
  padding: 4px;
  .progress-bar {
    height: 10px;
    border-radius: 5px;
  }
}
.rangeslider * {
  transition: all 0.4s ease-in-out;
}
.rangeslider__handle {
  transition: all 0.2s ease-in-out !important;
}
.account_page_title {
  margin-bottom: 30px;
  font-size: 2.25rem;
  line-height: 3.375rem;
}
.header_area_user {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .user_image_wrapper {
    width: 130px;
    height: 130px;
    margin-right: 20px;
    min-width: 130px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .user_content_details {
    .user_name {
      font-size: 20px;
      font-weight: 700;
      color: #050505;
      margin-bottom: 5px;
    }
    .user_email {
      font-size: 14px;
      color: #666666;
    }
  }
}
.user_nav_bar {
  position: relative;
  display: flex;
  margin-bottom: 30px;
  .navlink {
    padding: 10px 10px;
    margin-right: 25px;
    font-weight: normal;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #777777;
    border-bottom: 3px solid #ffffff;
    display: block;
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    &:hover,
    &.active,
    &:focus {
      text-decoration: none;
      color: #784a9e;
      border-color: #784a9e !important;
    }
  }
}
.edit_profile_icon_new {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 15px;
  background: #f7f7f7;
  color: #333333;
  border-radius: 20px;
  transition: all 0.4s ease-in-out;
  i {
    margin-right: 5px;
  }
  &:hover {
    text-decoration: none;
    background: #f1f1f1;
    color: #333333;
  }
}
.horizontal_course_card {
  border: 1px solid #dedede;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  background: #ffffff;
  transition: all 0.4s ease-in-out;
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
  .course_img {
    width: 130px;
    min-width: 130px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  &:hover {
    background: #f7f7f7;
    border-color: #dddddd;
  }
  .course_details {
    .course_link_title {
      display: block;
      margin-bottom: 0px;
      .course_link {
        color: #333333;
        display: block;
        font-size: 18px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .course_category {
      display: inline-block;
      font-size: 12px;
      text-transform: uppercase;
      padding: 2px 10px;
      border-radius: 4px;
      background: #f1f1f1;
      color: #333333;
      margin-bottom: 5px;
    }
  }
}
.courses_wrapper_container {
  padding: 60px 0px;
}
.card_new_wrapper {
  position: relative;
  border: 1px solid #eeeeee;
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: hidden;
  display: block;
  margin-bottom: 20px;
  &:hover {
    text-decoration: none;
  }
  .card_new_img {
    height: 172px;
    overflow: hidden;
    position: relative;
    .wish_icon {
      position: absolute;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      right: 15px;
      top: 15px;
      background: #e4e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        max-width: 20px;
        min-height: 20px;
      }
    }
    img {
      min-height: 172px;
    }
  }
  .card_new_body {
    padding: 18px;
    .card_new_title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 7px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      color: #0e314c;
    }
    .card_new_meta {
      font-size: 16px;
      display: block;
      margin-bottom: 7px;
      color: #8b8b8b;
    }
    .divider_b {
      margin-top: 15px;
      margin-bottom: 15px;
      border-top: 1px solid #e4e2e2;
    }
    .card_new_price {
      .price_span {
        font-weight: 700;
        font-size: 20px;
        font-weight: bold;
        color: #26a541;
      }
    }
    .details_button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .link_detail {
        font-weight: bold;
        font-size: 16px;
        color: #784a9e;
        display: block;
        padding: 10px 0px;
      }
      // a {
      //   color: #ffffff !important;
      //   &:hover {
      //     text-decoration: none;
      //   }
      // }
    }
  }
}
.btn-tt {
  padding: 10px 20px;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
  border-radius: 10px;
  transform: translateY(0px);
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
  &.primary {
    background: #784a9e;
    border-color: #784a9e !important;
    &:hover {
      background: #0062cc;
      border-color: #0062cc !important;
      box-shadow: 0 3px 8px 0 rgb(198 121 227 / 25%);
      transform: translateY(-2px);
    }
  }
  &.success {
    background: #26a541;
    &:hover {
      background: #1d7a31;
      border-color: #1d7a31 !important;
      box-shadow: 0 3px 8px 0 rgb(198 121 227 / 25%);
      transform: translateY(-2px);
    }
  }
}
.react-tabs__tab-list {
  border: none !important;
  .react-tabs__tab {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 20px;
    padding: 10px 20px !important;
    margin-bottom: 50px !important;
  }
  .react-tabs__tab--selected {
    border-color: #ffffff !important;
    background-color: #784a9e;
    color: #ffffff !important;
    border-radius: 10px !important;
  }
}

// New footer area
.footer-area {
  padding-top: 60px;
  position: relative;
  z-index: 1;
  background-color: #100f1f;
  color: #fff;
  font-weight: normal !important;
  .single-footer-widget {
    p {
      color: #d3d3d5;
      font-size: 14px;
    }
    h3 {
      font-size: 18px;
      margin-bottom: 30px;
      font-weight: 700;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        margin-bottom: 10px;
        a {
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          color: #fff;
          padding-left: 7px;
        }
      }
      &.footer-contact-info {
        margin-bottom: 20px;
        li {
          position: relative;
          color: #d3d3d5;
          margin-bottom: 8px;
          padding-left: 25px;
          display: flex;
          svg {
            position: absolute;
            left: 0;
            width: 15px;
            top: 0;
          }
          a {
            color: #bcbcbe;
          }
        }
      }
    }
    .social-links li {
      display: inline-block;
      margin-right: 5px;
      a {
        width: 32px;
        text-align: center;
        line-height: 29px;
        height: 32px;
        border: 1px solid transparent;
        border-radius: 3px;
        padding-left: 0px;
        &.facebook {
          background: #4267b2;
          color: #fff;
        }
        &.youtube {
          background: #ff0000;
          color: #fff;
        }
        &.linkedin {
          background: #0077b5;
          color: #fff;
        }
        &.instagram {
          background: linear-gradient(
            45deg,
            #f09433 0%,
            #e6683c 25%,
            #dc2743 50%,
            #cc2366 75%,
            #bc1888 100%
          );
          color: #fff;
        }
        &.linkedin {
          background: #0077b5;
          color: #fff;
        }
      }
    }
  }
}

.copyright-area {
  margin-top: 50px;
  border-top: 1px solid #3a3a3a;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  p {
    color: #d3d3d5;
  }
}
.login_wrapper {
  .mobile_number_wrapper {
    .otp_input {
      input {
        width: 2em !important;
        border-radius: 5px;
      }
      span {
        margin-left: 10px;
      }
    }
  }
}
.static-portion-wrapper {
  padding: 70px 0px;
  background-color: #211937;
  .static-portion-title {
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .static-portion-description {
    color: #eeeeee;
    // opacity: 0.6;
    margin-bottom: 30px;
  }
  .static-portion-body {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
}

.funfacts-area.bg-image {
  position: relative;
  z-index: 1;
  background-image: url("https://prepzone-public.s3.ap-south-1.amazonaws.com/funfacts-bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.funfacts-area.bg-image::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(
    135deg,
    #784a9e 0%,
    rgb(120 74 158 / 91%) 70%,
    #784a9e 100%
  );
}
.funfacts-area a:hover {
  text-decoration: none;
}
.funfacts-area.bg-image .section-title h2 {
  color: #fff;
}

.funfacts-area.bg-image .section-title p {
  color: #fff;
}

/*.funfacts-area.bg-image .contact-cta-box {
  border-color: #406883
}*/

.funfacts-area.bg-image .contact-cta-box h3 {
  color: #fff;
}

.funfacts-area.bg-image .contact-cta-box p {
  color: rgb(255 255 255 / 75%);
  font-weight: 600;
}

.single-funfact {
  background-color: rgb(255 255 255 / 10%);
  padding: 21px;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}
.single-funfact .icon {
  width: 90px;
  height: 50px;
}
.single-funfact .icon img {
  border-radius: 5px;
  max-width: 100%;
}
.single-funfact h3 {
  color: #fff;
  font-size: 17px;
  margin-bottom: 6px;
  font-weight: 700;
}
.funfact-detail {
  padding-left: 10px;
}
.single-funfact p {
  line-height: 1.5;
  color: #fff;
  font-size: 15px;
  margin-bottom: 0px;
}
.single-funfact:hover {
  background-color: rgb(255 255 255 / 15%);
}

.ptb-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.funfacts-area .section-title {
  text-align: center;
  margin-bottom: 50px;
}
.funfacts-area .section-title h2 {
  max-width: 520px;
  margin: 0 auto;
  line-height: 37px;
  font-size: 28px;
  font-weight: 700;
}

.contact-cta-box {
  margin: 15px auto 0;
  border: 1px dashed #c2c2c2;
  padding: 30px 210px 30px 30px;
  border-radius: 5px;
  position: relative;
}
.funfacts-area.bg-image .contact-cta-box h3 {
  color: #fff;
}

.contact-cta-box h3 {
  font-size: 20px;
  margin-bottom: 6px;
  font-weight: 800;
}
.funfacts-area.bg-image .contact-cta-box p {
  color: rgb(255 255 255 / 75%);
  font-weight: 600;
}

.contact-cta-box p {
  margin-bottom: 0;
}
.contact-cta-box .btn {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -27px;
}
.why-choose-us {
  // background: transparent url(../img/banner-bg1.jpg) right top no-repeat;
  position: relative;
  z-index: 1;
}

.why-choose-us .section-title {
  text-align: left;
}

.why-choose-us .section-title .bar {
  margin-left: 0;
  margin-right: 0;
}

.why-choose-us .section-title p {
  margin-left: 0;
  margin-right: 0;
}

.why-choose-us-image {
  position: relative;
  text-align: center;
}

.why-choose-us-image .main-pic {
  display: none;
}

.why-choose-us-image img:nth-child(1) {
  position: relative;
  left: -20px;
}

.why-choose-us-image img:nth-child(3) {
  position: absolute;
  top: -55px;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: -1;
}

.why-choose-us-image.single-why-choose-us-image img {
  display: block;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
}
.col-lg-6 .col-lg-6:nth-child(1) .single-why-choose-us {
  margin-top: 60px;
}

.col-lg-6 .col-lg-6:nth-child(2) .single-why-choose-us {
  margin-top: 0;
}

.col-lg-6 .col-lg-6:nth-child(4) .single-why-choose-us {
  margin-top: -30px;
}

.single-why-choose-us {
  padding: 30px 15px;
  border-radius: 5px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 0 20px rgba(56, 46, 46, 0.1);
  box-shadow: 0 0 20px rgba(56, 46, 46, 0.1);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-top: 30px;
}

.single-why-choose-us .icon {
  color: #2fbe5b;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-why-choose-us .icon i::before {
  font-size: 45px;
}

.single-why-choose-us h3 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-why-choose-us::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 5px;
  height: 4px;
  background: linear-gradient(135deg, #905cbc 0%, #1e1426 100%);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-why-choose-us p {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-why-choose-us:hover {
  -webkit-transform: translateY(-9px);
  transform: translateY(-9px);
  border-color: transparent;
}

.single-why-choose-us:hover::before {
  opacity: 1;
  visibility: visible;
}
.course_section_title {
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 30px;
}
.course_list_wwrr {
  margin: 0px -25px;
  .col-md-4 {
    padding: 0px 25px;
  }
}
.wish_lis_icon {
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #e4e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
  cursor: pointer;
}
.main_course_content {
  // border-top: 3px solid #E4E2E2;
  padding-top: 20px;
  position: relative;
  margin-top: -70px;
}
.btn-primary-sm {
  background: transparent;
  border: 1px solid #784a9e;
  padding: 6px 20px;
  border-radius: 10px;
  color: #784a9e;
  font-size: 14px;
  transition: all 0.4s ease-in-out;
  &:hover {
    color: #ffffff;
    background: #784a9e;
  }
}
.static-portion-video {
  div,
  iframe {
    width: 100% !important;
    // height: 100% !important;
  }
  iframe {
    margin-top: 30px;
  }
}
.mobile_search_container {
  display: none;
}
.navigation_right_part {
  display: flex;
  align-items: center;
}
.mobile_search_wrapper {
  * {
    transition: all 0.4s ease-in-out;
  }
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: flex-end;
  .close_layer {
    height: 100%;
    position: absolute;
    width: 100%;
  }
  .inner {
    position: relative;
    width: 100%;
    z-index: 9;
    background: #ffffff;
    height: 0px;
    transition: all 0.4s ease-in-out;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-topright: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
    &.height-auto {
      height: auto !important;
      min-height: 70vh;
    }
    .list_hanlder {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      span {
        width: 60px;
        height: 5px;
        border-radius: 10px;
        display: block;
        background: rgba(0, 0, 0, 0.1);
      }
    }
    .inner_body {
      padding: 20px;
      padding-top: 0px;
      .search-inner {
        position: relative;
        .form-control {
          font-size: 14px;
          height: 50px;
        }
        .search-icon-wrapper {
          position: absolute;
          right: 10px;
          font-size: 18px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .search-results {
        position: relative;
        top: 0px;
        left: 0px;
        max-height: 100%;
        width: 100%;
        padding-top: 20px;
      }
    }
  }
}
.navbar_right_item {
  cursor: pointer;
  position: relative;
  margin-right: 10px;
}
.notification_dot {
  position: absolute;
  width: 5px;
  height: 5px;
  right: 0px;
  top: 0px;
  border-radius: 50%;
  background: rgba(225, 25, 54, 1);
  border: 3px solid rgba(225, 25, 54, 0.5);
  animation: blink 2s;
  animation-iteration-count: infinite;
}
.notification_dropdown {
  left: auto;
  right: -60px;
  min-width: 300px;
  padding: 0px;
}
.notification_list {
  max-height: 300px;
  overflow-y: auto;
  .notification {
    display: flex;
    align-items: center;
    padding: 5px 0px;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    text-decoration: none;
    color: #333333;
    padding-left: 10px;
    padding-right: 10px;
    background: #f1f1f1;
    &.read {
      background: #ffffff;
    }
    .notification_image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      min-width: 40px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #784a9e;
      color: #ffffff;
    }
    .notification_content {
      font-size: 12px;
      .notification_date {
        display: block;
        font-weight: bold;
        color: #784a9e;
      }
    }
  }
}

@keyframes blink {
  50% {
    border-color: #fff;
  }
}

@media (max-width: 767px) {
  body {
    overflow-x: hidden;
  }
  .container-fluid-custom {
    padding: 0px 15px;
  }
  .header_search_wrapper {
    display: none !important;
  }
  .mobile_search_container {
    display: block;
    margin-right: 15px;
  }
  .contact-cta-box {
    padding: 20px;
    text-align: center;
    .btn {
      position: relative;
      margin-top: 30px;
      top: 0px;
      right: auto;
    }
  }
  .nav-item {
    .user_name {
      color: #000;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
  header {
    .dropdown-menu {
      right: 0px !important;
      left: auto !important;
    }
  }
  .static-portion-wrapper {
    text-align: center;
    .static-portion-title {
      text-align: center;
      font-size: 33px;
    }
    .static-portion-description {
      text-align: center;
    }
  }
  .react-tabs__tab-list .react-tabs__tab {
    margin-right: 0px;
    font-size: 14px;
    padding: 7px 10px !important;
    margin-bottom: 10px !important;
  }
  .card_new_wrapper {
    .card_new_body {
      .card_new_title {
        font-size: 20px;
      }
      .details_button .btn-tt {
        padding: 5px 10px;
        color: #fff;
        font-size: 20px;
        font-weight: normal;
        border-radius: 10px;
        transform: translateY(0px);
        transition: all 0.4s ease-in-out;
      }
    }
  }
  .top_banner_wrapper .banner_card {
    width: 100%;
    position: relative;
    top: 0;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .top_banner_wrapper .top_image_header {
    height: auto;
  }
  .top_banner_wrapper .banner_content {
    padding-top: 15vh;
  }
  .userPage_wrapper {
    padding: 20px 15px;
  }
  .mobile-not-flex {
    display: block !important;
  }
  .mobile-center {
    text-align: center !important;
  }
  .header_area_user .user_image_wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .user_nav_bar {
    flex-wrap: wrap;
    margin-bottom: 20px;
    .navlink {
      margin-right: 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 1;
      padding: 6px 10px;
    }
  }
  .horizontal_course_card {
    display: block;
    .course_img {
      width: 100%;
    }
    .course_details {
      .course_category {
        margin-bottom: 11px;
        margin-top: 10px;
      }
    }
  }
  .course_revires_container {
    padding: 15px 0px;
  }
  .video_controler_wrapper .bottom_controls .extra_controls .play_button_sm.mr {
    margin-right: 10px;
  }
  .video_controler_wrapper .bottom_controls .extra_controls .play_button_sm.ml {
    margin-left: 10px;
  }
  .video_controler_wrapper .bottom_controls .extra_controls .left_controls {
    position: relative;
    top: 10px;
  }
  .video_page_container .video_container .sidebar_component_wrapper {
    width: 400px;
    height: 100%;
    border-left: 1px solid #eeeeee;
    background: #ffffff;
    position: absolute;
    z-index: 999;
    overflow-y: auto;
    left: 0;
    top: 150px;
  }
}
.show_controls {
  opacity: 1 !important;
}
.hide_controls {
  opacity: 0 !important;
}
iframe {
  .ytp-expand-pause-overlay .ytp-pause-overlay,
  .ytp-pause-overlay-controls-hidden .ytp-pause-overlay {
    opacity: 0 !important;
  }
}
.resend_otp {
  display: inline-block;
  color: #784a9e;
  border-radius: 5px;
  padding: 3px 10px;
  font-weight: bold;
  cursor: pointer;
}
.breadcrumb_wrap {
  font-size: 15px;
  font-weight: normal;
  color: #2e3a59;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 20px;
  span {
    opacity: 0.6;
  }
}
.bundle_image_card {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 15px;
  height: auto;
  position: sticky;
  top: 120px;
  .bundle_image_wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  .bundle_card_details {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eeeeee;
    .bundle_expiry {
      font-size: 14px;
    }
    .bundle_price {
      font-size: 32px;
      font-weight: bold;
      color: #26a541;
      i {
        font-size: 28px;
      }
    }
  }
  .bundle_card_footer {
    padding-top: 10px;
    .bundle_includes {
      font-size: 14px;
      font-weight: bold;
      color: #2e3a59;
    }
  }
}
.bundle_details_section {
  .title {
    font-size: 34px;
    margin-bottom: 20px;
  }
}
.bundle_content_item {
  display: flex;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;
  color: #333333;
  &:hover {
    background-color: #f7f7f7;
    color: #333333;
    text-decoration: none;
  }
  .bundle_content_item_img {
    min-width: 150px;
    width: 150px;
    margin-right: 15px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .bundle_content_item_content {
    .bundle_content_item_category {
      display: inline-block;
      padding: 2px 10px;
      font-size: 12px;
      background: #f2f2f2;
      border-radius: 3px;
      margin-bottom: 5px;
    }
    .bundle_content_item_title {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
.bundle_content_wrapper {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
  border-radius: 10px;
  overflow: hidden;
  .bundle_content_search {
    padding: 10px;
    border-bottom: 1px solid #eeeeee;
  }
  .bundle_content_body {
    max-height: 400px;
    overflow-y: auto;
  }
}

.header_main {
  .nav_bar_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.desktop_menu {
  .nav_links {
    margin: 0px;
    padding: 0px;
    display: flex;
    list-style: none;
    .nav_link_item {
      display: flex;
      align-items: center;
      margin-left: 20px;
      &.button-shape {
        .navbar_link {
          background: $red;
          color: white;
          padding: 6px 15px;
          border-radius: 5px;
          font-size: 13px;
        }
      }
    }
    .navbar_link {
      font-weight: bold;
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;
      color: #666666;
      position: relative;
      .cursor-pointer {
        display: flex;
        align-items: center;
        a {
          font-weight: bold;
          text-transform: uppercase;
          transition: all 0.2s ease-in-out;
          color: #666666;
          position: relative;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .arrow_down_icon {
        transition: all 0.4s ease-in-out;
        transform: rotate(0deg);
        font-size: 20px;
        position: relative;
        top: 0px;
        display: inline-block;
        margin-left: 5px;
      }
      .navigation_dd_wrapper {
        position: absolute;
        width: 600px;
        top: 100px;
        z-index: 9999;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        border-radius: 5px;
        left: -50%;
        * {
          transition: all 0.4s ease-in-out;
        }
        .navigation_dd_parent {
          width: 40%;
          &:first-child {
            .parent_row_name {
              border-top: 3px solid #784a9e;
              -webkit-border-top-left-radius: 5px;
              -webkit-border-top-right-radius: 5px;
              -moz-border-radius-topleft: 5px;
              -moz-border-radius-topright: 5px;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }
          }
          &:last-child {
            .parent_row_name {
              -webkit-border-bottom-right-radius: 5px;
              -webkit-border-bottom-left-radius: 5px;
              -moz-border-radius-bottomright: 5px;
              -moz-border-radius-bottomleft: 5px;
              border-bottom-right-radius: 5px;
              border-bottom-left-radius: 5px;
            }
          }
          .parent_row_name {
            border-bottom: 1px solid #eeeeee;
            padding: 15px 10px;
            background: #ffffff;
            text-transform: none;
            color: #666666;
            font-weight: normal;
            border-left: 5px solid #ffffff;
            transition: all 0.2s ease-in-out;
            &:hover {
              background-color: #f9f9f9;
              border-left: 5px solid #784a9e;
              color: #784a9e;
            }
          }
          .navigation_dd_child {
            position: absolute;
            left: 40%;
            top: 0px;
            opacity: 0;
            visibility: hidden;
            width: 60%;
            right: 0px;
            background: #ffffff;
            height: 100%;
            padding: 0px 15px;
            overflow: auto;
            padding-top: 10px;
            .dd_course {
              display: flex;
              align-items: center;
              padding-bottom: 5px;
              margin-bottom: 5px;
              border-bottom: 1px solid #eeeeee;
              .dd_course_image {
                width: 70px;
                min-width: 70px;
                height: 50px;
                position: relative;
                overflow: hidden;
                border-radius: 5px;
                margin-right: 10px;
              }
              .dd_course_title {
                color: #666666;
                text-transform: none;
                font-size: 14px;
                font-weight: normal;
              }
              &:hover {
                .dd_course_title {
                  color: #784a9e;
                }
              }
            }
          }
          &:hover {
            .navigation_dd_child {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
      &:hover,
      &.active {
        color: #784a9e;
        text-decoration: none;
        .arrow_down_icon {
          transform: rotate(180deg);
        }
        .navigation_dd_wrapper {
          opacity: 1;
          visibility: visible;
          top: 20px;
          padding-top: 20px;
        }
        .navigation_dd_child {
          padding-top: 20px;
        }
      }
    }
  }
}
.header_main {
  padding: 10px 0;
}
.cursor-pointer {
  cursor: pointer;
}
.right_part_navbar {
  display: flex;
  align-items: center;
  .main_nav_bar {
    margin-right: 5rem;
  }
}

.course_rows_wrapper {
  margin-top: 20px;
  .course_row {
    margin-bottom: 50px;
    .course_row_header {
      .course_row_title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }
  }
}

.bg_dark {
  background: #211937;
}
.course_details_new {
  padding-top: 5rem;
  .left_details {
    .title {
      color: #fff;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
    }
    .description {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      margin-top: 15px;
      margin-bottom: 10px;
    }
    .time_line {
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
    }
  }
}
.course_main_details {
  padding: 40px 0;
}

.details_right_part {
  position: sticky;
  top: 120px;
  overflow: hidden;
  margin-top: -273px;
  padding: 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  .price_tag {
    font-weight: 900;
    font-size: 36px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $dark;
  }
  .video_wrapper {
    height: 220px;
    overflow: hidden;
    img {
      width: 100%;
      min-height: 220px;
    }
  }
  .right_part_details {
    background-color: #ffffff;
    padding: 20px 13px;
    .btn-large {
      padding: 20px;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      border-radius: 10px;
      border: 1px solid;
      transition: all 0.4s ease-in-out;
      &:hover {
        text-decoration: none;
        background-color: transparent;
        color: #784a9e;
        border: 1px solid #784a9e !important;
      }
    }
    .desccription {
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .price {
      h3 {
        font-weight: 900;
        font-size: 36px;
        line-height: 22px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .coupon_text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      opacity: 0.6;
      display: block;
      margin-top: 3px;
    }
    .course_content_right_part {
      margin-top: 20px;
      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  .cc_footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    padding: 10px;
    .cc_title {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      i {
        margin-right: 3px;
      }
    }
    .payment_image {
      max-width: 215px;
      margin: 0 auto;
      margin-top: 10px;
    }
  }
}

.title_sc {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
}
.description_sc {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin-top: 15px;
}
.accordion_wrapper {
  .heading {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f3f4f6;
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      padding-left: 5px;
      margin-bottom: 0px;
    }
  }
  .body_content {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
}
.section_row {
  margin: 10px 0;
  .row_icon {
    width: 30px;
  }
}

// * * * * *  author_wrapper
.author_wrapper {
  .author {
    margin-top: 20px;
    .author_top_section {
      display: flex;
      align-items: center;
      .author_image {
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 50%;
        img {
          width: 100% !important;
        }
      }
      .author_details {
        padding-left: 20px;
        .name {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
        }
        .designation {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          opacity: 0.7;
        }
      }
    }
    .author_description {
      margin-top: 30px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.blog_details_wrapper {
  .bradcrumb_wrapper {
    padding: 4px 8px;
    padding-right: 20px;
    line-height: 1;
    background-color: rgba(120, 74, 158, 0.2);
    width: -webkit-max-content;
    width: max-content;
    margin-top: 10px;
    border-radius: 2px;
    a {
      color: #6b7c93;
      font-size: 0.8rem;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}

// * * * blog_header
.blog_header {
  padding: 20px 0px;
  .blog_title {
    font-weight: 600;
    line-height: 1.5;
    font-size: 28px;
    margin-bottom: 20px;
  }
  .author {
    font-size: 14px;
    opacity: 0.8;
    .author_name {
      color: #784a9e;
      font-weight: bold;
    }
  }
}
.blog_content_wrapper,
.pr_content {
  margin-bottom: 20px;
  margin-top: 20px;
  p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.88;
    margin: 16px 0 0;
  }
  img {
    display: block;
    max-width: 100%;
    margin: 20px auto;
  }
  h1,
  h2,
  h3,
  h4 {
    color: #784a9e;
    font-weight: 600;
    margin-top: 15px;
  }
  h2 {
    font-size: 30px;
    margin-top: 20px;
  }
  h3 {
    font-size: 28px;
    margin-top: 20px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 20px;
  }
  ul {
    padding-left: 30px;
    margin: 15px 0;
    li {
      list-style: circle;
      line-height: 2;
    }
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }

  th,
  td {
    text-align: left;
    padding: 8px;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}

.social_share {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: #ffffff;
  width: 40px;
  // padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  -webkit-border-top-right-radius: 30px;
  -webkit-border-bottom-right-radius: 30px;
  -moz-border-radius-topright: 30px;
  -moz-border-radius-bottomright: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  z-index: 999;
  button {
    margin: 1px 0px;
    i {
      font-size: 24px;
    }
  }
}
.social_media_icons {
  margin-top: 2.5rem;
  display: flex;
  .social_icon {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    border-radius: 7px;
    transition: all 0.4 ease-in-out;
    &:hover {
      background-color: #ffffff;
      color: #784a9e;
    }
  }
}

.home-slider-wrapper {
  position: relative;
  .slick-dots {
    bottom: 7px;
  }
}
.slide_arrows {
  display: flex;
  position: absolute;
  width: 100%;
  z-index: 9;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 15px;
  .arrow {
    font-size: 22px;
    cursor: pointer;
  }
}
.stats_wrapper_home {
  padding: 2.5rem 0;
  .py-6 {
    padding: 1.5rem 0px;
  }
  .btn-large {
    padding: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid;
    transition: all 0.4s ease-in-out;
    &:hover {
      text-decoration: none;
      background-color: transparent;
      color: #784a9e;
      border: 1px solid #784a9e !important;
    }
  }
  .stats_box {
    display: flex;
    align-items: center;
    .stats_image {
      min-width: 40px;
      width: 40px;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }
    .stats_content {
      font-weight: 400;
      font-size: 14px;
      strong {
        font-weight: bold;
        font-size: 17px;
      }
    }
  }
}
.custom_row {
  // background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  .custom_row_image {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  .custom_row_content {
    position: relative;
    .counter_big {
      position: absolute;
      right: 10px;
      font-size: 150px;
      font-weight: 900;
      opacity: 0.1;
      color: #784a9e;
      top: -40px;
    }
    .tagline {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .tagline_sap {
        width: 100%;
        max-width: 43px;
        min-height: 2px;
        margin-right: 20px;
        background-color: #784a9e;
      }
      .tagline_title {
        font-size: 16px;
        line-height: 1.125em;
        font-weight: 600;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #784a9e;
      }
    }
    .title {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 20px;
      // color: #784a9e;
    }
    .description {
      line-height: 1.433em;
      font-weight: 400;
      letter-spacing: 0.3px;
      color: #161616;
    }
  }
}
.custom_flex_column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-blogs-wrapper {
  padding: 2.5rem 0px;
  .blogs_wrapper {
    padding: 2.5rem 0px;
    .title {
      font-size: 36px;
      font-weight: 600;
    }
  }
}
.footer_new_version {
  padding-top: 3.5rem;
  padding-bottom: 1.5rem;
  background: #211937;
  -webkit-border-top-left-radius: 100px;
  -webkit-border-top-right-radius: 100px;
  -moz-border-radius-topleft: 100px;
  -moz-border-radius-topright: 100px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  font-size: 14px;
  .top_footer_area {
    margin-bottom: 2rem;
  }
}
.top_footer_area {
  .title {
    line-height: 58px;
    font-size: 3rem;
    font-weight: 900;
  }
}
.button_custom_footer {
  box-shadow: 0 0 0 20px #211937, 0 0 0 24px #784a9e;
  border-radius: 100px;
  transition: all 0.4s ease-in-out;
  &:hover {
    text-decoration: none;
  }
  span {
    background-color: #ffc107;
    border-radius: 100px;
    padding: 30px 60px;
    display: block;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #fff;
  }
  &:hover {
    box-shadow: 0 0 0 10px #211937, 0 0 0 14px #784a9e;
  }
}
.text_red {
  color: #f44336;
}
.footer_last_link {
  color: #ffc107;
  margin-left: 7px;
}
.footer_links {
  .footer_links_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  ul {
    padding: 0px;
  }
}
.footer_links_even {
  li {
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    a {
      opacity: 0.7;
      transition: all 0.4s ease-in-out;
      font-size: 14px;
      color: #fff;
      &:hover {
        opacity: 1;
        text-decoration: none;
      }
    }
  }
}
.footer_links_odd {
  li {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    i {
      width: 30px;
      position: relative;
    }
  }
}
.bg_dark_dark {
  background: #110c20;
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.mobile_navbar {
  display: none;
}

.mobile_new_menu_wrapper {
  position: fixed;
  width: 100vw;
  height: 100%;
  left: 0;
  top: 0px;
  background: #ffffff;
  z-index: 9999;
  padding: 20px;
  overflow-y: auto;
  .mobile_new_menu_inner {
    .mobile_new_menu_item {
      cursor: pointer;
      padding: 10px 0;
      border-bottom: 1px solid #eee;
      .mobile_nav_link {
        font-size: 16px;
        color: #161616;
        font-weight: 600;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.mm_top_left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
  margin-bottom: 20px;
}
.mobile_course_dropdown_wrapper {
  background-color: #f2f2f2;
  padding: 15px 10px;
  border-radius: 10px;
  margin-top: 15px;
}
.course_category_wrapper {
  margin: 5px 0;
  .child_row {
    border-bottom: 1px solid #eeeeee;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.user_auth_area {
  a {
    &:hover {
      text-decoration: none;
    }
    .user_name {
      color: #000000;
    }
  }
}

.right_fixed_button {
  position: fixed;
  right: -40px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  display: flex;
  flex-wrap: nowrap;
  z-index: 999;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #25d366;
  padding: 8px 15px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .call_back_btn {
    font-weight: 600;
    text-transform: capitalize;
    display: block;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .header_main {
    .desktop_navbar {
      display: none;
    }
  }
  .mobile_navbar {
    display: block;
    background: #ffffff;
    &.scrolled {
      box-shadow: 0px 3px 9px -3px rgba(0, 0, 0, 0.1);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
    }
    .top_section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      .mobile_menu_icon {
        width: 50px;
        height: 40px;
        border-radius: 5px;
        border: none;
        position: relative;
        right: -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        font-size: 35px;
        cursor: pointer;
      }
    }
  }
  .course_description {
    margin-top: 20px;
  }
}
.dropdown-menu-mobile-menu {
  list-style: none;
  padding: 10px;
  margin: 0;
  .dropdown-item-mobile {
    display: flex;
    align-items: center;
    color: #000000;
    width: 100%;
    padding: 5px 0;
    i {
      min-width: 30px;
      width: 30px;
    }
    &:hover {
      text-decoration: none;
    }
  }
}

.download_btn_icc {
  display: inline-block;
  background: #784a9e;
  border-radius: 5px;
  border: 1px solid #784a9e;
  padding: 6px 15px;
  margin-left: 5px;
  color: #ffffff;
}

.contact_mentor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  color: #ffffff;
  background: #25d366;
  padding: 15px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  gap: 10px;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
  border: 1px solid #25d366;
  &:hover {
    text-decoration: none;
    color: #25d366;
    background: #ffffff;
  }
}

@media (max-width: 767px) {
  .stats_wrapper_home {
    .stats_box {
      flex-direction: column;
      .stats_content {
        text-align: center;
        strong {
          display: block;
        }
      }
    }
  }
  .top_strip_wrapper {
    padding-right: 14px !important;
    .ts_content {
      flex-direction: flex;
      gap: 10px;
    }
  }
  .custom_row {
    .custom_row_image {
      img {
        width: 100%;
      }
    }
    .custom_row_content {
      .tagline {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 40px;
      }
      .title {
        text-align: center;
        font-size: 24px;
      }
      .description {
        text-align: center;
      }
    }
  }
  .top_footer_area .title {
    text-align: center;
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  .button_custom_footer {
    box-shadow: none;
    width: 100%;
    span {
      text-align: center;
      padding: 20px 40px;
    }
  }
  .mobile_content_center {
    justify-content: center !important;
  }
  .footer_new_version {
    -webkit-border-top-left-radius: 40px;
    -webkit-border-top-right-radius: 40px;
    -moz-border-radius-topleft: 40px;
    -moz-border-radius-topright: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }
  .about_footer {
    margin-bottom: 30px;
  }
  .blog_image {
    img {
      width: 100%;
    }
  }
  .blog_details_wrapper {
    .bradcrumb_wrapper {
      width: 100%;
    }
  }
  .contact_us_wrapper {
    .right_section {
      margin-top: 30px;
    }
  }
  .contact_us_wrapper .contact_us_inner .left_section .all_info .ff_row {
    .ff_row_icon {
      width: 30px;
    }
    .content {
      a {
        font-size: 14px;
      }
    }
  }
  .breadcrumb_wrap {
    white-space: normal !important;
  }
  .bundle_image_card {
    margin-top: 20px;
  }
}
.top_strip_wrapper {
  box-shadow: 0px -4px 3px -3px rgba(0, 0, 0, 0.1);
  background: #f3e8ff;
  padding: 12px 0;
  font-size: 14px;
  font-weight: 600;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  .ts_content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .strip_btn {
    background: #784a9e;
    transition-delay: 0.1s;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-radius: 0.375rem;
    color: #ffffff;
    white-space: nowrap;
    &:hover {
      text-decoration: none;
      background: #6b21a8;
    }
  }
  .close_st {
    position: absolute;
    opacity: 0.7;
    transition: all 0.4s ease-in-out;
    right: 0;
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}
.practice_forum_wrapper {
  padding: 40px 0;
  .all_quiz_wrapper {
    .quiz_item {
      background: #ffffff;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 20px;
      border: 1px solid #eeeeee;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      top: 0;
      display: block;
      color: #212529;
      transform: scale(1);
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      background-color: #ffffff;
      .quiz_item_title {
        display: flex;
        margin: 0px;
        font-size: 18px;
        .arrow_icon {
          margin-right: 10px;
          width: 20px;
          min-width: 20px;
          font-size: 18px;
          position: relative;
          top: 2px;
          color: #28a745;
          transition: all 0.4s ease-in-out;
        }
      }
      &:hover {
        background-color: #784a9e;
        color: #fff;
        top: 0;
        transform: scale(1.05);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        text-decoration: none;
        .arrow_icon {
          color: #fff;
        }
      }
    }
  }
}
.prac_details_wrapper {
  padding: 40px 0;
  .question_header {
    margin-bottom: 30px;
    .title {
      font-size: 34px;
      margin-bottom: 10px;
    }
  }
}
.question_ww {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #dadada;
  .question_title {
    background: #784a9e;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
  }
}
.option_list {
  padding-top: 30px;
  padding-left: 0px;
  li {
    list-style: upper-alpha !important;
    display: flex;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    border: 2px solid #dadada;
    cursor: pointer;
    background: $white;
    transition: all 0.4s ease-in-out;
    .checkbox {
      width: 20px;
      min-width: 20px;
      height: 20px;
      border-radius: 5px;
      margin-right: 10px;
      border: 1px solid #cccccc;
      display: inline-block;
      background: #eeeeee;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 4px;
      i {
        display: none;
        font-size: 13px;
      }
      &.checked {
        background-color: #28a745;
        i {
          display: block;
          color: #fff;
        }
      }
    }
    &.right_ans {
      border-color: #1a8917;
      background-color: rgba($color: #1a8917, $alpha: 0.1);
    }
    &.wrong_ans {
      border-color: #891717;
      background-color: rgba($color: #891717, $alpha: 0.2);
    }
  }
}

.artilcles_table {
  .table {
    th {
      background: #784a9e;
      color: #fff;
    }
    td {
      a {
        font-style: italic;
        text-decoration: underline;
        color: #784a9e;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.solution_wrapp {
  border: 5px solid #edeff0;
  border-radius: 7px;
  padding: 10px 12px;
}
.client-flex {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;
  .testi-box {
    background: #fff;
    border-radius: 5px;
    padding: 22px 25px;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    -webkit-box-shadow: 0 2px 24px 0 rgb(0 0 0 / 8%);
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 8%);
  }
  .testi-box:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 90px;
    background: linear-gradient(135deg, #44245e 0%, #64437f 100%);
    color: #fff;
  }
  .testi-box:after {
    content: "";
    display: block;
    width: 132px;
    height: 90px;
    // background: url(../../../images/quote-pattern.html) no-repeat center center;
    position: absolute;
    top: -23px;
    right: -14px;
    transform: scale(0.6);
  }
  .person-img {
    display: inline-block;
    vertical-align: middle;
    width: 90px;
    height: 90px;
    margin-right: 10px;
    border-radius: 100%;
    overflow: hidden;
    border: 2px solid #6b3b91;
    padding: 4px;
    position: relative;
  }
  .person-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    overflow: hidden;
  }
  .testi-info {
    display: inline-block;
    vertical-align: text-bottom;
    width: calc(100% - 105px);
    position: relative;
    color: #fff;
  }
  .testi-info h3 {
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 15px;
    font-weight: 700;
  }
  .testi-info h4 {
    font-size: 14px;
    margin: 0;
    color: #dcd3e3;
  }
  .review-text {
    margin-top: 15px;
  }
  .review-text p {
    line-height: 26px;
  }
  .testi-box iframe {
    border-radius: 6px;
  }
}
.nav_link_item {
  position: relative;
  .navigation_dd_w {
    position: absolute;
    width: 225px;
    top: 100px;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);

    * {
      transition: all 0.4s ease-in-out;
    }
    .inner {
      border-top: 3px solid #784a9e;
      height: 100%;
      background: #fff;
      overflow: hidden;
      border-radius: 5px;
      min-height: 80px;
      padding: 10px;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      .nav_it {
        &:not(:last-child) {
          border-bottom: 1px solid #eeeeee;
        }

        padding: 10px 0;
      }
    }
  }
  &:hover {
    .navigation_dd_w {
      opacity: 1;
      visibility: visible;
      top: 20px;
      padding-top: 20px;
    }
  }
}

.testimonials_wrapper {
  background: #f5f5f5;
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  margin-bottom: 40px;
  .testimonial_card_wrapper {
    position: relative;
    margin: 15px;
    position: relative;
    overflow: hidden;
    padding: 15px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    .top_section {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 15px;
      padding-bottom: 10px;
      .testimonial_card_image {
        width: 70px;
        min-width: 70px;
        height: 70px;
        margin-right: 20px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
      }
      .testimonial_card_content {
        .testimonial_card_title {
          font-size: 18px;
          font-weight: 600;
        }
        .testimonial_card_description {
          font-size: 12px;
        }
      }
    }
    .body {
      .testimonial_card_content {
        opacity: 0.9;
        min-height: 145px;
      }
    }
  }
}

.testimonial_slider {
  overflow: hidden;
  .slide_arrows {
    width: 100%;
    left: 0;
  }
}

@media (max-width: 767px) {
  .client-flex {
    column-count: 1;
  }
  .client-flex .testi-box:before {
    height: 120px;
  }
  .client-flex .person-img {
    position: relative;
    top: -10px;
  }
  .top_strip_wrapper .close_st {
    top: -20px;
    left: 0px !important;
    right: auto;
    transform: translateY(0px);
    background-color: #f3e8ff;
    border-radius: 50%;
  }
}
.padding-top-normal {
  padding-top: 40px;
}
// * * * * about_us_container
.about_us_container {
  .about_intro {
    .page_name {
      text-align: center;
      margin-bottom: 18px;
      font-size: 16px;
      font-weight: 700;
    }
    .page_title {
      margin-bottom: 24px;
      font-size: 62px;
      line-height: 1;
      font-weight: 700;
      text-align: center;
    }
    .description {
      max-width: 800px;
      margin: 0 auto 37px;
      font-size: 22px;
      text-align: center;
    }
    .about_intro__image {
      margin-bottom: -10px;
      img {
        max-width: 800px;
        display: block;
        margin: 0 auto;
      }
    }
  }
  .abt_section_bg {
    padding: 140px 0px;
    background: #f5f5f5;
    &.white_bg {
      background: #fff;
    }
  }
  .left_section_content {
    padding-right: 30px;
    .section_title {
      font-size: 48px;
      line-height: 1.1;
      margin-top: 0px;
      font-weight: 700;
      line-height: 60px;
    }
  }
  .right_section_content {
    padding-left: 30px;
  }
  .main_content {
    font-size: 20px;
    margin-bottom: 1.4em;
    line-height: 1.4;
  }
  .section__media {
    margin-top: 40px;
  }
  .info-item {
    margin-bottom: 30px;
    h4 {
      font-size: 26px;
      line-height: 1.3;
      font-weight: 700;
      position: relative;
      margin-bottom: 15px;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.45;
    }
  }
}
.mb-65 {
  margin-bottom: 60px;
}
.small_about_section {
  padding-top: 82px;
  &.gray_bg {
    background: #f5f5f5;
  }
  .small_about_intro {
    .page_title {
      font-size: 38px;
      margin-bottom: 20px;
      line-height: 1.14;
      font-weight: 700;
      text-align: center;
    }
    .description {
      margin-bottom: 1.4em;
      line-height: 1.45;
      text-align: center;
      font-size: 22px;
    }
  }
}
.ss_image_container {
  text-align: center;
  margin-top: 20px;
  position: relative;
  top: 30px;
}
.team_container {
  margin-top: 50px;
}
.team_card_wrapper {
  font-size: 15px;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 50px;
  .team_card_image {
    margin-bottom: 20px;
    .inner {
      display: inline-block;
      margin: 0 auto;
      width: 100px;
      height: 100px;
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      img {
        height: auto;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
      }
    }
  }
  .team_card_content {
    .title {
      margin-bottom: 12px;
      line-height: 1;
      font-size: 26px;
      font-weight: 700;
    }
    .designation {
      margin-bottom: 17px;
      font-size: 14px;
    }
    .description {
      font-size: 15px;
      line-height: 1.6;
      text-align: center;
    }
  }
}
.padding_bb {
  padding-bottom: 0px !important;
}
.get_start_img {
  text-align: center;
}

.submit_quiz_btn {
  background-color: white;
  padding: 10px 20px;
  .btn-primary {
    width: 300px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  &.fixed_btn {
    position: sticky;
    left: 0px;
    width: auto;
    z-index: 9999;
    bottom: 0px;
  }
}

@media (max-width: 767px) {
  .about_us_container {
    img {
      max-width: 100%;
    }
  }
  .about_us_container .about_intro .page_title {
    font-size: 40px;
  }
  .about_us_container .about_intro .about_intro__image {
    img {
      max-width: 100%;
    }
  }
  .about_us_container .left_section_content .section_title,
  .about_us_container .about_intro .page_title {
    font-size: 30px;
    line-height: 1.3;
    text-align: center;
  }
  .about_us_container .section__media {
    margin-bottom: 20px;
  }
  .about_us_container .main_content {
    text-align: center;
  }
  .about_us_container .abt_section_bg {
    padding: 80px 0px;
  }
  .about_us_container .info-item {
    text-align: center;
  }
  .about_us_container .right_section_content {
    padding-left: 0px;
  }
  .about_us_container .left_section_content {
    padding-right: 0px;
  }
  .about_us_container .main_content {
    font-size: 18px;
    margin-bottom: 1.4em;
    line-height: 1.7;
  }
  .main_quiz_row {
    flex-direction: column-reverse;
  }
  .quiz_timer_wrapper {
    margin-bottom: 20px;
    &.fixed {
      position: fixed;
      left: 10px;
      width: calc(100% - 20px);
      z-index: 9999;
      top: 10px;
    }
  }
  .details_right_part {
    position: relative;
    margin-top: 0px;
  }
  .submit_quiz_btn {
    background-color: transparent;
    padding: 0;
    .btn-primary {
      width: calc(100% - 20px);
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    &.fixed_btn {
      position: sticky;
      left: 0px;
      width: 100%;
      z-index: 9999;
      bottom: 10px;
    }
  }
}
.quiz_timer_wrapper {
  position: sticky;
  top: 0;
  transition: all 0.3s ease-in-out;

  .quiz_timer_counter {
    padding: 20px;
    background: #784a9e;
    color: #fff;
    overflow: hidden;
    border-radius: 10px;
    &.red_alert {
      background: #e32636;
    }
    .title {
      text-align: center;
    }
    .inner {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-weight: 700;
      .qt {
        padding: 0 10px;
        min-width: 60px;
        text-align: center;
      }
    }
  }
}
.btn-reverse-primary {
  background: #ffffff;
  border-color: #ffffff;
  color: #784a9e;
  transition: all 0.4s ease-in-out;
  &:hover,
  &:focus {
    background: #784a9e;
    border-color: #ffffff;
    color: #ffffff;
  }
}

.link_extended {
  white-space: nowrap;
  color: #784a9e;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}
.primary_text_color {
  color: #784a9e;
}
.hideindesktop {
  display: none !important;
}
.hideinmobile {
  display: block !important;
}
@media (max-width: 767px) {
  .hideinmobile {
    display: none !important;
  }
  .hideindesktop {
    display: block !important;
  }
  .blog_content_wrapper,
  .blog_header,
  .bradcrumb_wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  .social_share {
    bottom: 0;
    left: 0;
    top: auto;
    transform: none;
    background: #ffffff;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    border-top: 1px solid #eeeeee;
    border-radius: 0px !important;
    &.fixed_btn {
      position: fixed;
    }
  }
  .social_share button {
    margin: 1px 10px;
  }
}
.top_bg_section_new {
  background: #f6f6f6;
  padding: 40px 0px;
  margin-top: 40px;
  margin-bottom: -40px;
}
.results_wrapper {
  .top_bg_section {
    background: #f6f6f6;
    padding-bottom: 80px;
  }
  .top_section {
    padding-top: 50px;
    padding-bottom: 30px;
    .title {
      margin-bottom: 0;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.2;
    }
    .sub_title {
      font-size: 50px;
      font-weight: 700;
      color: #2fbe5b;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .description {
      font-size: 15px;
      line-height: 1.7;
      padding-top: 10px;
    }
    .img_wrapper {
      padding-left: 50px;
    }
  }
}
.results_tab_wrapper {
  .result_tabs_headings {
    display: flex;
    justify-content: center;
    .inner {
      display: flex;
      justify-content: center;
      position: relative;
      top: -34px;
      background: #ffffff;
      padding: 5px;
      border-radius: 8px;
      -webkit-box-shadow: 0 13px 38px 0 rgb(45 31 80 / 27%);
      box-shadow: 0 13px 38px 0 rgb(45 31 80 / 27%);
    }
  }
  .react-tabs__tab-list .react-tabs__tab {
    margin: 0 !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    border-radius: 8px !important;
    text-transform: capitalize;
  }
  .react-tabs__tab-list .react-tabs__tab--selected::after {
    background-color: transparent !important;
  }
}
.result_card_wrapper {
  padding: 10px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  -webkit-box-shadow: 0 6px 22px 0 rgb(0 0 0 / 8%);
  box-shadow: 0 6px 22px 0 rgb(0 0 0 / 8%);
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  .result_card_image {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    height: 250px;
    img {
      width: 100%;
    }
    .result_card_year {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      display: inline-block;
      padding: 10px 25px;
      border-radius: 8px;
      background-color: #784a9e;
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .result_card_content {
    padding: 15px;
    padding-bottom: 10px;
    .result_card_title {
      font-size: 18px;
    }
    .percentile {
      color: #784a9e;
      font-weight: bold;
      &.percentile_box {
        min-height: 70px;
      }
    }
  }
}
@media (max-width: 767px) {
  .results_wrapper .top_section .sub_title {
    font-size: 30px;
  }
  .results_wrapper .top_section .img_wrapper {
    padding-left: 0px;
  }
  .results_tab_wrapper .result_tabs_headings .inner {
    top: -24px;
  }
  .viewer-layout-toolbar {
    display: none !important;
  }
}
.isError_form {
  // background-color: #f8d7da;
  color: #ea4852;
  // border: 1px solid #f5c2c7;
  display: block;
  margin-top: 5px;
  // padding: 3px 10px;
  border-radius: 4px;
  font-size: 12px !important;
}
.form_predictor_header {
  background-color: $red;
  color: #ffffff;
  padding: 20px 15px;
  font-size: 24px;
  font-weight: bold;
}
.form_predictor_wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #eeeeee;
  .form_predictor_body {
    padding: 25px 15px;
  }
}
.table-primary {
  background-color: $red !important;
  th {
    background-color: $red !important;
    border-color: $red !important;
    color: #ffffff;
  }
}
.form_predictor_results {
  padding: 20px 0px;
}
.ppd_wrapper {
  padding: 40px 0;
  margin-bottom: 60px;
  .ppd_content {
    .title {
      font-size: 40px;
      font-weight: 700;
      color: $red !important;
    }
    .desc {
      font-size: 18px;
    }
    .subtitle {
      font-size: 28px;
    }
    .emojii {
      position: relative;
      top: 6px;
    }
  }
}
.pp_image_wrapper {
  padding: 0 30px;
  overflow: hidden;
  max-height: 240px;
}
.pp_animation {
  position: relative;
  top: -150px;
}
.form_predictor_body {
  .login_label {
    font-size: 15px !important;
  }
  .form-control {
    font-size: 13px !important;
    height: 40px;
    background: #f3f3f4 !important;
    color: #0d0c22 !important;
    border: 1px solid transparent !important;
    -webkit-transition: background-color 200ms ease, outline 200ms ease,
      color 200ms ease, -webkit-box-shadow 200ms ease;
    transition: background-color 200ms ease, outline 200ms ease,
      color 200ms ease, -webkit-box-shadow 200ms ease;
    transition: background-color 200ms ease, outline 200ms ease,
      color 200ms ease, box-shadow 200ms ease;
    transition: background-color 200ms ease, outline 200ms ease,
      color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease;

    &:hover,
    &:focus {
      border-color: rgba(0, 0, 0, 0.1) !important;
      background-color: #fff !important;
      -webkit-box-shadow: 0 0 0 4px rgb(120 74 158 / 10%) !important;
      box-shadow: 0 0 0 4px rgb(120 74 158 / 10%) !important;
    }
  }
  .btn-primary {
    padding: 13px 15px;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 1px;
  }
}
.imp_label {
  color: #ea4852;
}
.info_heading_label {
  color: $red;
  font-size: 20px;
  margin: 20px 0px;
  text-align: center;
  font-weight: bold;
}
.celebration_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: -270px;
  z-index: 999;
}
.attention_title {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
}
.attention_desc {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  opacity: 0.7;
}
.custom_program_btn {
  color: $red;
  display: inline-block;
  margin-bottom: 10px;
  border: 1px solid $red;
  padding: 10px 20px;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
  &:hover,
  &:focus {
    background: $red;
    color: #ffffff;
    text-decoration: none;
  }
}
.back_btn_link {
  margin-right: 10px;
  background: transparent;
  border: none;
  color: #ffffff !important;
  &:hover {
    text-decoration: none;
  }
}
.auto_play_trigger {
  position: absolute;
  right: 70px;
  top: 13px;
  display: flex;
  gap: 10px;
  color: #ffffff;
  align-items: center;
}

@media (max-width: 767px) {
  .ppd_wrapper .ppd_content .title {
    text-align: center;
    font-size: 30px;
  }
  .pp_animation {
    top: 0px;
  }
  .ppd_wrapper .ppd_content .desc,
  .ppd_wrapper .ppd_content .subtitle {
    text-align: center;
  }
  .ppd_wrapper .ppd_content .subtitle {
    font-size: 22px;
  }
  .form_predictor_header {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .top_strip_wrapper .strip_btn {
    white-space: nowrap !important;
  }
}
.btn-whatsapp {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #25d366;
  color: #ffffff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  text-transform: capitalize;
  padding: 10px 10px;
  background: #25d366;
  transition: all 0.4s ease-in-out;
  .iconss {
    font-size: 20px;
  }
  &:hover {
    background: #ffffff;
    color: #25d366;
  }
}

.animationII {
  padding: 30px 0;
  max-width: 400px;
  margin: 50px auto;
  .inner {
    min-width: 50%;
    min-height: 500px;
  }
}
.mycourses_navlist {
  // border-color: transparent !important;
  .nav-link {
    border-radius: 8px !important;
    color: #444444 !important;
    margin-bottom: 15px !important;
    &.active {
      background-color: #784a9e !important;
      color: #ffffff !important;
    }
  }
}

.subs_form_wrapper * {
  overflow: hidden !important;
}

.subs_form_wrapper iframe {
  position: relative;
  left: -35px;
  top: -60px;
  padding: 0px !important;
}
.loader_home {
  min-height: 400px;
  background: #f1f1f1;
  position: relative;
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.cta_buttons_wrapper {
  border-bottom: 2px solid #784a9e;
  background: #f3e8ff;
}

.cta_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 5px;
}
.cta_btn_custom {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  padding: 3px 40px;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
}

.cta_btn_bg_primary {
  background: #784a9e;
  color: #ffffff;
  border: 1px solid #784a9e;
}
.cta_btn_bg_whatsapp {
  background: #25d366;
  color: #ffffff;
  border: 1px solid #25d366;
}
.cta_btn_bg_darkgreen {
  background: #0062cc;
  color: #ffffff;
  border: 1px solid #0062cc;
}
.cta_btn_bg_yellow {
  background: #dbb20e;
  color: #ffffff;
  border: 1px solid #dbb20e;
}
.cta_btn_bg_yt {
  background: #ff0000;
  color: #ffffff;
  border: 1px solid #ff0000;
}
.cta_btn_bg_insta {
  background: radial-gradient(
      circle farthest-corner at 28% 100%,
      #fcdf8f 0%,
      #fbd377 10%,
      #fa8e37 22%,
      #f73344 35%,
      transparent 65%
    ),
    linear-gradient(145deg, #3051f1 10%, #c92bb7 70%);
  color: #ffffff;
}
.cta_btn_custom_social {
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 20px;
  &:hover {
    color: #ffffff;
  }
}

.desktop_header {
  position: relative;
  background: #ffffff;
  &.scrolled {
    box-shadow: 0px 3px 9px -3px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
}

// right_navbar_wrapper
.right_navbar_wrapper {
  position: fixed;
  bottom: 80px;
  right: 10px;
  max-width: 1880px;
  z-index: 999;
  .right_navbar_inner {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .course_packages_icon {
      position: fixed;
      bottom: 213px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 15px;
      border-radius: 50px;
      right: 10px;
      width: 50px;
      height: 50px;
      background: #784a9e;
      color: #ffffff;
      animation: expandWidth 5s infinite ease-in-out;
      overflow: hidden;
      &:hover {
        text-decoration: none;
      }
      .cpi_inner {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 15px;
        span {
          line-height: 1;
          font-size: 14px;
        }
      }
    }
    .whatsapp_icon {
      background: #25d366;
      color: #ffffff;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      a {
        color: #ffffff;
      }
    }
    .call_icon {
      background: #e63145;
      color: #ffffff;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      a {
        color: #ffffff;
      }
    }
  }
}

@keyframes expandWidth {
  0% {
    width: 50px;
  }
  50% {
    width: 140px;
  }
  100% {
    width: 50px;
  }
}

.social_icon_buttonss {
  display: flex;
  align-items: center;
  gap: 15px;
}
.onlyS_mobile {
  display: none;
}

@media (max-width: 767px) {
  .hideMN,
  .desktop_header {
    display: none !important;
  }
  .right_fixed_button {
    right: -40px;
  }
  .main_container {
    overflow: hidden !important;
  }
  .cta_btn_custom {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    padding: 3px 10px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 12px;
    transition: all 0.4s ease-in-out;
    min-height: 30px;
    &:hover {
      text-decoration: none;
    }
  }
  .cta_buttons_wrapper > .container {
    padding-left: 10px !important;
    padding-right: 10px !important;
    .cta_buttons {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  .mfb_btn {
    display: none !important;
  }
  .onlyS_mobile {
    display: block;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 999;
    .btn-tt {
      border-radius: 0px !important;
    }
    .btn-large {
      border: none !important;
      min-height: 55px;
    }
  }
  .details_right_part {
    top: 0px;
  }
}

// feedback_card_wrapper
.feedback_card_wrapper {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px;
  margin-bottom: 20px;
  .feedback_card_inner {
    .feedback_card_header {
      display: flex;
      gap: 20px;
      .feedback_card_profile {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        .feedback_card_profile_first_letter {
          background-color: #784a9e;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          color: #ffffff;
          font-weight: bold;
          font-size: 28px;
        }
      }
      .feedback_card_profile_content {
        .feedback_card_name {
          font-size: 20px;
          font-weight: 800;
          margin-bottom: 0px;
        }
        .feedback_card_rating {
          display: flex;
          gap: 4px;
          position: relative;
          top: -5px;
        }
      }
    }
    .feedback_card_content {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #eeeeee;
      position: relative;
      .quote_comma_icon {
        position: absolute;
        font-size: 60px;
        opacity: 0.2;
        left: -6px;
        top: -25px;
      }
      p {
        font-style: italic;
        position: relative;
        opacity: 0.8;
        .empty_space {
          display: inline-block;
          width: 50px;
          min-height: 25px;
        }
      }
    }
  }
}
